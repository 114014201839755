import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";

import {faEnvelope, faMapMarkerAlt} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import logo from "@assets/images/logo/static.png";
import {useStores} from "@hooks/useStores";
import {Documents} from "@components/common/documents/Documents";
import {SocialLinks} from "@components/common/SocialLinks";
import {PdfFile} from "@components/common/pdf-file/PdfFile";

import feasibilityStudy from "@assets/files/FeasibilityStudy.pdf";
import feasibilityStudyRu from "@assets/files/FeasibilityStudy-ru-translated.pdf";
import aurousFinanceRu from "@assets/files/aurous-finance-presentation-ru.pdf";
import aurousFinanceEn from "@assets/files/aurous-finance-presentation-en.pdf";

import {Language} from "../../constants";

const presentationMap: Record<Language, string> = {
  [Language.RU]: aurousFinanceRu,
  [Language.EN]: aurousFinanceEn,
  [Language.ES]: aurousFinanceEn,
  [Language.FR]: aurousFinanceEn,
  [Language.IT]: aurousFinanceEn,
};

const feasibilityStudyMap: Record<Language, string> = {
  [Language.RU]: feasibilityStudyRu,
  [Language.EN]: feasibilityStudy,
  [Language.ES]: feasibilityStudy,
  [Language.FR]: feasibilityStudy,
  [Language.IT]: feasibilityStudy,
};

export const Footer = () => {
  const {globalStore, accountStore} = useStores();

  const {t} = useTranslation();

  const whitePaperUri = useMemo(() => {
    return presentationMap[globalStore.language];
  }, [globalStore.language]);

  const feasibilityStudyUri = useMemo(() => {
    return feasibilityStudyMap[globalStore.language];
  }, [globalStore.language]);

  return (
    <section id="footer" className="d-flex align-items-end flex-1 bg-black-2 pt-5 pb-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-3 text-center mt-4 mt-lg-0 mb-lg-0 order-last order-lg-0">
            <img src={logo} width={100} alt="Aurous"/>
            <div className="tx-14 tx-gray-700 mt-3 mt-md-5 pt-md-5">
              &copy; {(new Date()).getFullYear()} {t('footer.all-rights-reserved')}.
            </div>
          </div>

          <div className="col-md-3 col-lg-2 mb-5 text-center text-md-start">
            <div className="tx-18 tx-bold mb-3">
              {t('footer.files')}
            </div>

            <div className="mt-2">
              <PdfFile name={t('footer.white-paper') + ''} fileUri={whitePaperUri} />
            </div>

            {accountStore.isLoggedIn && (
              <>
                <div className="mt-2">
                  <PdfFile name={t('footer.feasibility-study') + ''} fileUri={feasibilityStudyUri} />
                </div>
                <div className="mt-2">
                  <Documents />
                </div>
              </>
            )}
          </div>

          <div className="col-md-4 col-lg-2 mb-5 text-center text-md-start">
            <div className="tx-18 tx-bold mb-3">
              {t('footer.links')}
            </div>

            <SocialLinks />

            <div className="mt-2">
              <a href="https://dzen.ru/aurous_finance" className="tx-white" target="_blank" rel="noreferrer">
                {t('footer.blog')}
              </a>
            </div>

            <div className="mt-2">
              <a href="https://t.me/AUROUS_FINANCE_CHANNEL" className="tx-white" target="_blank" rel="noreferrer">
                {t('footer.news')}
              </a>
            </div>

            <div className="mt-2">
              <a href="https://www.youtube.com/@aurous.finance" className="tx-white" target="_blank" rel="noreferrer">
                {t('footer.videos')}
              </a>
            </div>
          </div>

          <div className="col-md-5 col-lg-5 text-center text-md-start">
            <div className="tx-18 tx-bold mb-3">
              {t('footer.contacts')}
            </div>

            <div className="d-flex justify-content-center justify-content-md-start mb-3">
              <div className="tx-16 tx-gold me-3">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
              </div>
              <div className="flex-md-1 tx-gray-400">
                <p><span className="notranslate">Rainbow Minerals Mining</span> (Certificate #57510, Sudan)</p>
              </div>
            </div>

            <div className="d-flex justify-content-center justify-content-md-start mb-3">
              <div className="tx-16 tx-gold me-3">
                <FontAwesomeIcon icon={faMapMarkerAlt}/>
              </div>
              <div className="flex-md-1 tx-gray-400">
                <p className="notranslate">12 Sheikh Hospital Street, Al Khurtum, Sudan</p>
              </div>
            </div>

            <div className="d-flex justify-content-center justify-content-md-start mb-3">
              <div className="tx-16 tx-gold me-3">
                <FontAwesomeIcon icon={faEnvelope}/>
              </div>
              <div className="flex-md-1 tx-gray-400">
                <a className="notranslate tx-gray-400" href="mailto:marketing@aurous.finance">marketing@aurous.finance</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
