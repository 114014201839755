import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faEyeSlash} from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import {faKey} from "@fortawesome/pro-regular-svg-icons/faKey";

import IconWithLoading from "@components/common/IconWithLoading";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";

import {PageRoutes} from "../../../../constants";

interface ChangePasswordRequestProps {
  token: string;
}

export const ChangePasswordRequest = ({ token }: ChangePasswordRequestProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {accountStore} = useStores();
  const handleResponse = useResponseHandler();

  const [isLoading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);

  const isValidPasswordsInput = useCallback(() => {
    if (confirmPassword !== newPassword) {
      handleResponse(t('error.passwords-not-match') + '', true);
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    return true;
  }, [newPassword, confirmPassword, handleResponse, t]);

  const handlePasswordChange = useCallback(() => {
    if (!isValidPasswordsInput()) {
      return;
    }

    setLoading(true);
    accountStore.changePasswordWithToken(token, newPassword)
      .then((response) => {
        if (response.success) {
          setNewPassword('');
          setConfirmPassword('');
          handleResponse(t('profile.password-changed') + '', false);
          setTimeout(() => {
            navigate(PageRoutes.LOGIN);
          }, 5000);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [token, newPassword, accountStore, handleResponse, isValidPasswordsInput, t, navigate]);

  return (
    <div className="card-body">
      <form
        id="login"
        className="flex-1"
        onSubmit={(e) => {e.preventDefault(); handlePasswordChange()}}
      >
        <div id="reset-request-form" className="wd-100p wd-md-500">
          <div className="ms-3 tx-18 mb-1">{t('profile.new-password')}</div>
          <div className="form-group mb-2">
            <div className="input-group">
              <input
                type={showNewPassword ? "text" : "password"}
                className="form-control"
                placeholder={t('profile.new-password') + ''}
                value={newPassword}
                id="new-password"
                onChange={(event) => setNewPassword(event.target.value)}
                disabled={isLoading}
              />
              <div className="input-group-text">
                <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} role="button" onClick={() => setShowNewPassword((prevState) => !prevState)}/>
              </div>
            </div>
          </div>

          <div className="ms-3 tx-18 mb-1">{t('profile.confirm-password')}</div>
          <div className="form-group mb-2">
            <div className="input-group">
              <input
                type={showNewPassword ? "text" : "password"}
                className="form-control"
                placeholder={t('profile.confirm-password') + ''}
                value={confirmPassword}
                id="confirm-new-password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                disabled={isLoading}
              />
              <div className="input-group-text">
                <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} role="button" onClick={() => setShowNewPassword((prevState) => !prevState)}/>
              </div>
            </div>
          </div>

          <button className="btn btn-black tx-primary mt-2 mt-lg-0 order-2 order-md-1" type="submit" disabled={isLoading}>
            <IconWithLoading
              icon={faKey}
              isLoading={isLoading}
            />
            {t('profile.change-password')}
          </button>
        </div>
      </form>
    </div>
  );
};
