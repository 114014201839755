import React, {useCallback} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-hot-toast";
import {faCopy} from "@fortawesome/pro-solid-svg-icons/faCopy";
import {useTranslation} from "react-i18next";

export interface CopyButtonProps {
  text: string;
  alertMessage?: string;
  element?: React.ReactNode;
  className?: string;
  iconClass?: string;
  noIcon?: boolean;
}

export default function CopyButton(props: CopyButtonProps
) {
  const {t} = useTranslation();
  const {
    text,
    element,
    alertMessage = t('common.copied') + '',
    className = 'btn btn-link p-0 tx-left',
    iconClass = 'tx-white tx-12 me-2',
    noIcon = false,
  } = props;
  const handleCopy = useCallback(() => {
    toast.success(alertMessage + '', {duration: 5000});
  }, [alertMessage]);

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <button className={className}>
        {!noIcon && <FontAwesomeIcon icon={faCopy} className={iconClass}/>}{element !== undefined ? element : text}
      </button>
    </CopyToClipboard>
  );
}
