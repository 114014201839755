import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {Pages} from "../../constants";
import logo from "@assets/images/logo/staticTop.png";
import {LanguageSwitcher} from "@components/common/LanguageSwitcher/LanguageSwitcher";
import {useTranslation} from "react-i18next";
import {Nav, Navbar} from "react-bootstrap";
import {useLocation} from "react-router-dom";

const Menubar = observer(() => {
  const {t} = useTranslation();
  const {accountStore} = useStores();
  const location = useLocation();

  const handleLogout = () => {
    accountStore.logout();
  };

  return (
    <div id="menubar">
      <Navbar expand="lg" variant="dark">
        <div className="container tx-center tx-lg-left px-5 py-3">
          <Navbar.Brand href="/">
            <img src={logo} alt="Aurous Finance" className="wd-150"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <div className="d-block d-lg-none">
              <LanguageSwitcher />
            </div>

            <Nav className="ms-auto me-auto">
              {Pages.map(({title, path}) => (
                <Nav.Link href={path} key={`nav-item-${path}`} active={path === location.pathname}>
                  {t(title)}
                </Nav.Link>
              ))}
            </Nav>

            <div className="d-none d-lg-block">
              <LanguageSwitcher />
            </div>

            {accountStore.isLoggedIn && (
              <button
                className="btn btn-link tx-white tx-uppercase px-0"
                onClick={handleLogout}
              >
                {t('common.logout')}
              </button>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
      {/*{accountStore.isLoggedIn && (*/}
      {/*  <>*/}
      {/*    <Link to={PageRoutes.WALLET} className="logo tx-center">*/}
      {/*      <img src={logo} alt="Aurous Finance" className="wd-60"/>*/}
      {/*    </Link>*/}
      {/*    <LanguageSwitcher />*/}
      {/*    {tokenBank && (*/}
      {/*      <div className="mt-3 mb-3">*/}
      {/*        <div>{t('common.current-price')} AUF:</div>*/}
      {/*        <div className="tx-bold mb-2">{tokenBank.aufPrice}$</div>*/}
      {/*        <div>{t('common.until-rise')}:</div>*/}
      {/*        <div className="tx-bold">{tokenBank.nextAufUpdateIn}$</div>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*    <div className="sidebar-nav mt-auto mb-auto">*/}
      {/*      {Pages.map(({title, path, icon}) => {*/}
      {/*        const isActive = location.pathname === path || (location.pathname === PageRoutes.ROOT && path === PageRoutes.WALLET);*/}

      {/*        if (path === PageRoutes.PROFILE && !globalStore.isAdmin) return null;*/}

      {/*        return (*/}
      {/*          <Link*/}
      {/*            key={`sidebar-page-link-${title}-${path}`}*/}
      {/*            to={path}*/}
      {/*            className={`btn my-1 ${isActive ? 'btn-dark' : 'btn-primary tx-dark'}`}*/}
      {/*            onClick={toggleMenu}*/}
      {/*          >*/}
      {/*            {isActive && (<span className="corners"/>)}*/}
      {/*            {icon && <FontAwesomeIcon icon={icon} className="me-2" />}*/}
      {/*            {t(title)}*/}
      {/*          </Link>*/}
      {/*        )*/}
      {/*      })}*/}
      {/*    </div>*/}

      {/*    <button*/}
      {/*      className="btn btn-dark mt-auto mb-0"*/}
      {/*      onClick={handleLogout}*/}
      {/*    >*/}
      {/*      <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />*/}
      {/*      {t('common.logout')}*/}
      {/*    </button>*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  )
});

export default Menubar;
