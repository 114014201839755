import {useStores} from "@hooks/useStores";
import React, {useCallback, useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import {Link, useNavigate} from "react-router-dom";
import IconWithLoading from "@components/common/IconWithLoading";
import {faUserPlus} from "@fortawesome/pro-duotone-svg-icons/faUserPlus";
import {emailRx, PageRoutes, usernameRx} from "../../../constants";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faEyeSlash} from "@fortawesome/pro-regular-svg-icons/faEyeSlash";

export default function Register() {
  const {t} = useTranslation();
  const {accountStore} = useStores();
  const handleResponse = useResponseHandler();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [partnerID, setPartnerID] = useState(accountStore.refID || '');

  const isValidInput = useCallback(() => {
    if (username.length < 4 || username.length > 12) {
      handleResponse(t('error.username-length') + '', true);
      return false;
    }

    if (!(usernameRx.test(username))) {
      handleResponse(t('error.wrong-username') + '', true);
      return false;
    }

    if (email.length < 6 || !(emailRx.test(email))) {
      handleResponse(t('error.wrong-email') + '', true);
      return false;
    }

    if (password.length < 8 || password.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return false;
    }

    if (passwordRepeat !== password) {
      handleResponse(t('error.passwords-not-match') + '', true);
      return false;
    }

    if (!partnerID) {
      handleResponse(t('error.no-referral') + '', true);
      return false;
    }

    return true;
  }, [partnerID, username, email, password, passwordRepeat, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (!isValidInput()) {
      return;
    }

    setLoading(true);
    accountStore.register(username, email, password, partnerID)
      .then((response) => {
        if (response.success) {
          handleResponse(t('register.success') + '', false);
          setTimeout(() => {
            navigate(PageRoutes.LOGIN);
          }, 5000);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [accountStore, navigate, username, email, password, partnerID, isValidInput, handleResponse, t]);

  return (
    <div className="container">
      <div className="card p-0 bg-dark">
        <h2 className="mb-3 mt-3 ms-5 tx-24">{t('register.title')}</h2>

        <div className="card ht-100p gradient-primary">
          <div className="card-body">
            <form
              id="register"
              className="flex-1"
              onSubmit={(e) => {e.preventDefault(); submitForm();}}
            >
              <div>
                <div id="login-form" className="wd-100p wd-md-500">
                  <div className="form-group mb-2">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder={`${t('common.username')}`}
                      value={username}
                      id="username"
                      onChange={(event) => {
                        const value = event.target.value;

                        if (value.length - username.length < 3) {
                          setUsername(value)
                        } else {
                          if (usernameRx.test(value)) {
                            setUsername(value);
                          } else {
                            setUsername(username);
                          }
                        }
                      }}
                      disabled={isLoading}
                    />
                  </div>

                  <div className="form-group mb-2">
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      placeholder="Email"
                      value={email}
                      id="email"
                      onChange={(event) => {
                        const value = event.target.value;

                        if (value.length - email.length < 3) {
                          setEmail(value)
                        } else {
                          if (emailRx.test(value)) {
                            setEmail(value);
                          } else {
                            setEmail(email);
                          }
                        }
                      }}
                      disabled={isLoading}
                    />
                  </div>

                  <div className="form-group mb-2">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control form-control-lg"
                        placeholder={t('common.password') + ''}
                        value={password}
                        id="password"
                        onChange={(event) => setPassword(event.target.value)}
                        disabled={isLoading}
                      />
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} role="button" onClick={() => setShowPassword((prevState) => !prevState)}/>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-2">
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control form-control-lg"
                        placeholder={t('common.password-repeat') + ''}
                        value={passwordRepeat}
                        id="password-repeat"
                        onChange={(event) => setPasswordRepeat(event.target.value)}
                        disabled={isLoading}
                      />
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} role="button" onClick={() => setShowPassword((prevState) => !prevState)}/>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-2">
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder={t('common.referral') + ''}
                      value={partnerID}
                      id="partner-id"
                      onChange={event => setPartnerID(event.target.value)}
                      disabled={isLoading}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-black tx-primary wd-100p"
                      type="submit"
                      disabled={isLoading}
                    >
                      <IconWithLoading icon={faUserPlus} className="me-2" isLoading={isLoading} />
                      {t('common.register')}
                    </button>
                  </div>

                  <div className="mt-2">
                    {t('register.have-account')} <Link to={PageRoutes.LOGIN}>{t('common.login')}</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
