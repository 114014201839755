import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {ENV, PageRoutes} from "./constants";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Register from "@pages/Register";
import Login from "@pages/Login";
import Wallet from "@pages/Wallet";
import Menubar from "@components/common/Menubar";
import {useStores} from "@hooks/useStores";
import Invest from "@pages/Invest";
import useQuery from "@hooks/useQuery";
import Referral from "@pages/Referral";
import Withdrawal from "@pages/Withdrawal";
import Profile from "@pages/Profile";
import useResponseHandler from "@hooks/useResponseHandler";
import {useTranslation} from "react-i18next";
import History from "@pages/History";
import Actions from "@pages/Actions";
import {Footer} from "@components/common/Footer";

import './styles/main.scss';
import ResetPassword from "@pages/ResetPassword";
import {LanguageSwitcher} from "@components/common/LanguageSwitcher/LanguageSwitcher";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global BigInt */

const publicRoutes = [PageRoutes.LOGIN, PageRoutes.REGISTER, PageRoutes.PASSWORD_RESET];
const protectedRoutes = Object.values(PageRoutes).filter(route => !publicRoutes.includes(route));

const App = observer(() => {
  const {t} = useTranslation();
  const {accountStore, globalStore} = useStores();
  const isLoggedIn = accountStore.isLoggedIn;
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const handleResponse = useResponseHandler();
  accountStore.handleReferral(query);

  // const toggleMenu = () => {
  //   document.body.classList.toggle('open-nav')
  // };

  useEffect(() => {
    if (location.hash === '#clearmydirtybrowser') {
      localStorage.clear();
    }
  }, [location]);

  useEffect(() => {
    if (location.hash === '#amahustler') {
      globalStore.setAdmin(true);
    }
  }, [globalStore, location]);

  useEffect(() => {
    if (isLoggedIn && publicRoutes.includes(location.pathname as PageRoutes)) {
      navigate(PageRoutes.WALLET);
    }
  }, [navigate, location, isLoggedIn]);

  useEffect(() => {
    if (location.pathname === PageRoutes.PASSWORD_RESET && query.get('token')) {
      accountStore
        .passwordReset(query.get('token')!)
        .then((response) => {
          if (response.status === 200) {
            handleResponse(t('alert.password-changed') + '', false);
          } else {
            handleResponse(response.data, true);
          }
        })
        .catch((response) => {
          handleResponse(response.response.data);
        })
        .finally(() => {
          navigate(PageRoutes.LOGIN);
        });
    }
  }, [t, handleResponse, location, query, accountStore, navigate])

  if (ENV.IS_UNDER_MAINTENANCE && !globalStore.isAdmin) {
    return (
      <div className="wd-100p ht-100p d-flex flex-column">
        <div className="p-3">
          <LanguageSwitcher />
        </div>
        <div id="under-construction-content" className="bg-overlay p-4 p-md-5 pt-navbar flex-1 d-flex flex-column justify-content-center align-items-center">
          <h1 className="tx-36 tx-lg-42 tx-center d-flex flex-1 align-items-end">
            {t('common.under-maintenance')}
          </h1>
          <h2 className="tx-24 tx-lg-32 tx-center d-flex flex-1 align-items-start">
            {t('common.coming-soon')}
          </h2>
        </div>
        <div className="d-flex align-items-end">
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div className="wd-100p ht-100p">
      {/*<div id="topbar">*/}
      {/*  <button onClick={toggleMenu} className="hamburger"><div/></button>*/}
      {/*</div>*/}
      {/*<div className="overlay" onClick={toggleMenu}/>*/}
      {/*{isLoggedIn && <Sidebar />}*/}
      <div id="main-content" className="d-flex flex-column pb-3 pb-md-5">
        <Menubar/>
        <Routes>
          {!isLoggedIn && (
            <>
              <Route path={PageRoutes.ROOT} element={<Login/>}/>
              <Route path={PageRoutes.LOGIN} element={<Login/>}/>
              <Route path={PageRoutes.REGISTER} element={<Register/>}/>
              <Route path={PageRoutes.RESET_PASSWORD} element={<ResetPassword/>}/>
              <Route path={PageRoutes.PASSWORD_RESET} element={<Login/>}/>
              {protectedRoutes.map(route => (
                <Route key={route} path={route} element={<Login />}/>
              ))}
            </>
          )}
          {isLoggedIn && (
            <>
              <Route path={PageRoutes.ROOT} element={<Wallet/>}/>
              <Route path={PageRoutes.WALLET} element={<Wallet/>}/>
              <Route path={PageRoutes.HISTORY} element={<History/>}/>
              <Route path={PageRoutes.INVEST} element={<Invest/>}/>
              <Route path={PageRoutes.WITHDRAWAL} element={<Withdrawal/>}/>
              <Route path={PageRoutes.REFERRAL} element={<Referral/>}/>
              <Route path={PageRoutes.PROFILE} element={<Profile/>}/>
              <Route path={PageRoutes.ACTIONS} element={<Actions/>}/>
            </>
          )}
        </Routes>
        <Footer />
      </div>
    </div>
  );
});

export default App;
