import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {useEffect, useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import {Currency, ITokenBank, IWallet, PageRoutes} from "../../../constants";
import FormattedNumber from "@components/common/FormattedNumber";
import {useTranslation} from "react-i18next";
import walletWave from "@assets/images/wallet-wave.svg";
import walletAft from "@assets/images/coins/aft.png";
import walletGat from "@assets/images/coins/gat.png";
import walletUsdt from "@assets/images/coins/usdt.png";
import walletGold from "@assets/images/wallet-gold.png";
import cardHistory from "@assets/images/card-history.png";
import cardInvest from "@assets/images/card-invest.png";
import cardWithdrawal from "@assets/images/card-withdrawal.png";
import cardReferral from "@assets/images/card-referral.png";
import {mapCurrency} from "@helpers/mapCurrency";
import Deposit from "@pages/Deposit";
import {Link} from "react-router-dom";

const Wallet = observer(() => {
  const {t} = useTranslation();
  const {accountStore, globalStore} = useStores();

  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [tokenBank, setTokenBank] = useState<ITokenBank>();
  const handleResponse = useResponseHandler();

  useEffect(() => {
    accountStore.getWallets()
      .then((response) => {
        const priority = [Currency.AFT, Currency.GAT, Currency.USDT];
        setWallets((response || []).sort((a, b) => priority.indexOf(mapCurrency(a.currency)) - priority.indexOf(mapCurrency(b.currency))));
      })
      .catch((response) => {
        handleResponse(response.response.data);
      });
  }, [accountStore, handleResponse]);

  useEffect(() => {
    globalStore.getTokenBank().then(setTokenBank);
  }, [globalStore]);

  return (
    <div id="wallet" className="container">
      <div className="card gradient-primary mb-4 p-4 p-sm-5">
        <h1 className="mb-4">{t('wallet.balance')}</h1>

        <div className="wallets d-flex flex-wrap">
          {wallets.map((wallet, index) => {
            let currencyBalance;
            if (mapCurrency(wallet.currency) === Currency.AFT && tokenBank?.aufPrice) {
              currencyBalance = wallet.balance * tokenBank.aufPrice + ((wallet.metadata.fakeBalance || 0) * tokenBank.aufPrice);
            } else if (mapCurrency(wallet.currency) === Currency.GAT && tokenBank?.gatPrice) {
              currencyBalance = wallet.balance * tokenBank.gatPrice + ((wallet.metadata.fakeBalance || 0) * tokenBank.gatPrice);
            } else {
              currencyBalance = wallet.usdBalance + (wallet.metadata.fakeBalance || 0);
            }

            return (
              <div key={index} className="wd-350 ht-260 mb-4 me-lg-5">
                <div className="card ht-100p gradient-primary-135deg p-4 z-index-10">
                  <div className="card-body">
                    <img src={walletWave} alt="wallet-bg" className="wallet-wave" />
                    <img src={mapCurrency(wallet.currency) === Currency.AFT ? walletAft : wallet.currency === Currency.GAT ? walletGat : walletUsdt} alt="wallet" className="wallet-coin" />
                    <div className="position-relative mb-3">
                      <FormattedNumber
                        value={wallet.balance + (wallet.metadata.fakeBalance || 0)}
                        postfix={mapCurrency(wallet.currency) === Currency.USDT ? undefined : mapCurrency(wallet.currency)}
                        suffix={mapCurrency(wallet.currency) === Currency.USDT ? '$' : undefined}
                        decimals={2}
                        className="tx-26 tx-bold pe-3"
                        floor
                      />
                      {mapCurrency(wallet.currency) !== Currency.USDT && currencyBalance > 0 && (
                        <>
                          {' '}
                          <FormattedNumber
                            value={currencyBalance}
                            suffix={'≈$'}
                            decimals={2}
                            className="tx-14"
                            floor
                          />
                        </>
                      )}
                    </div>
                    {wallet.currency === Currency.GAT && (
                      <>
                        <FormattedNumber
                          value={tokenBank?.gatSoldCount}
                          suffix={`${t('wallet.bought')}: `}
                          postfix={Currency.GAT}
                          decimals={2}
                          className="d-block position-relative tx-16 pe-5"
                          floor
                        />
                        <FormattedNumber
                          value={tokenBank?.gatPrice || wallet.usdRate}
                          suffix={`${t('wallet.price')}: $`}
                          decimals={5}
                          className="d-block position-relative tx-16"
                          floor
                        />
                      </>
                    )}
                    {mapCurrency(wallet.currency) === Currency.AFT && (
                      <>
                        <FormattedNumber
                          value={tokenBank?.nextAufUpdateIn}
                          suffix={`${t('wallet.before-increase')}: $`}
                          decimals={2}
                          className="d-block position-relative tx-16 pe-5"
                          floor
                        />
                        <FormattedNumber
                          value={tokenBank?.aufPrice || wallet.usdRate}
                          suffix={`${t('wallet.price')}: $`}
                          decimals={5}
                          className="d-block position-relative tx-16"
                          floor
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <img src={walletGold} alt="wallet-gold" className="wallet-gold" />
      </div>

      <div className="row">
        <div className="col-12 col-lg-8 mb-4">
          <Deposit />
        </div>
        <div className="col-md-6 col-lg-4 ht-300 ht-lg-400 mb-4">
          <Link to={PageRoutes.HISTORY} className="card gradient-primary-down ht-100p">
            <h2 className="tx-24 tx-uppercase">{t('wallet.history')}</h2>
            <img src={cardHistory} alt="card-history" className="card-icon icon-history" />
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 ht-300 ht-lg-400 mb-4">
          <Link to={PageRoutes.INVEST} className="card gradient-primary-down ht-100p">
            <h2 className="tx-24 tx-uppercase">{t('wallet.investment')}</h2>
            <img src={cardInvest} alt="card-invest" className="card-icon" />
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 ht-300 ht-lg-400 mb-4">
          <Link to={PageRoutes.WITHDRAWAL} className="card gradient-primary-down ht-100p">
            <h2 className="tx-24 tx-uppercase">{t('wallet.withdrawal')}</h2>
            <img src={cardWithdrawal} alt="card-withdrawal" className="card-icon" />
          </Link>
        </div>
        <div className="col-md-6 col-lg-4 ht-300 ht-lg-400 mb-4">
          <Link to={PageRoutes.REFERRAL} className="card gradient-primary-down ht-100p">
            <h2 className="tx-24 z-index-10 tx-uppercase">{t('wallet.referral-program')}</h2>
            <div className="card bg-dark wd-100 card-absolute">
              <img src={cardReferral} alt="card-referral" className="card-icon icon-referral" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
});

export default Wallet;
