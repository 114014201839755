import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Currency, IReferralSubLine} from "../../../../constants";
import useResponseHandler from "@hooks/useResponseHandler";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";

interface TreeLine {
  username: string;
  lines: IReferralSubLine[];
}

const ReferralSubLines = observer(() => {
  const {t} = useTranslation();
  const {accountStore} = useStores();
  const [treeLines, setTreeLines] = useState<TreeLine[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const handleResponse = useResponseHandler();
  const [isInitial, setIsInitial] = useState<boolean>(true);

  const openNewLine = useCallback((username: string) => {
    setIsLoading(true);
    accountStore.getReferralSubLine(username)
      .then((response) => {
        if (response.success && response.data) {
          setTreeLines((prevState) => {
            return [...prevState, {username, lines: response.data!}];
          });
        } else {
          handleResponse(response);
        }
        setIsLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setIsLoading(false);
      });
  }, [accountStore, handleResponse]);

  const handleEmptyPartnersHidden = useCallback(() => {
    accountStore.setEmptyPartnersHidden(!accountStore.emptyPartnersHidden);
  }, [accountStore]);

  useEffect(() => {
    if (!isInitial) {
      return;
    }

    setIsInitial(false);

    accountStore.getProfileInfo()
      .then((response) => {
        openNewLine(response.username);
      })
      .catch((response) => {
        handleResponse(response.response.data);
      });
  }, [isInitial, openNewLine, accountStore, handleResponse]);

  const currentLine = treeLines[treeLines.length - 1];

  const currentLinePartners = useMemo(() => {
    if (!currentLine) {
      return [];
    }
    if (accountStore.emptyPartnersHidden) {
      return currentLine.lines.filter(line => line.personalTurnover !== 0);
    }
    return currentLine.lines;
  }, [accountStore.emptyPartnersHidden, currentLine]);

  return (
    <div id="referral-sub-lines">
      <h1 className="d-flex mt-5 mb-4 heading">
        <span>{t('referral.sublines-title')}</span>
        <span className="action"><label>{t('referral.hide-partners-with-empty-deposit')} <input type="checkbox" defaultChecked={accountStore.emptyPartnersHidden} onClick={handleEmptyPartnersHidden} /></label></span>
      </h1>

      <div className="mb-2">
        {treeLines.map((treeLine, index) => {
          const isLast = index === treeLines.length - 1;
          return (
            <div key={treeLine.username} className="d-inline">
              {index !== 0 && (<FontAwesomeIcon icon={faChevronRight} className="mx-2 tx-14"/>)}
              {!isLast && (
                <button
                  className="btn btn-link tx-primary text-decoration-none tx-left px-0 py-0"
                  onClick={() => setTreeLines((prevState) => prevState.slice(0, index + 1))}
                >
                  {treeLine.username}
                </button>
              )}
              {isLast && (
                <span className="tx-white tx-bold">{treeLine.username}</span>
              )}
            </div>
          )
        })}
      </div>

      <div>
        {isLoading && (<Preloader inline />)}
        {!isLoading && currentLine && (
          <div className="table-wrapper">
            <table className="table mb-0">
              <thead className="bg-dark tx-white">
                <tr className="valign-middle tx-center">
                  <th>{t('referral.line')}</th>
                  <th>{t('common.username')}</th>
                  <th>Email</th>
                  <th>{t('referral.rank.personal-turnover')}</th>
                  <th>{t('referral.sublines.branch-turnover')}</th>
                  <th>{t('referral.sublines.whole-turnover')}</th>
                </tr>
              </thead>
              <tbody className="bg-gradient">
                {!isLoading && !currentLinePartners.length && (
                  <tr className="tx-bold tx-center">
                    <td colSpan={6}>{t('referral.no-sublines')}</td>
                  </tr>
                )}
                {currentLinePartners.map((line) => (
                  <tr key={`${currentLine.username}-subline-${line.username}`} className="tx-center">
                    <td>{line.referralLine}</td>
                    <td>
                      <button
                        className="btn btn-link tx-left px-0 py-0"
                        onClick={() => openNewLine(line.username)}
                      >
                        {line.username}
                      </button>
                    </td>
                    <td>{line.email}</td>
                    <td>
                      <FormattedNumber
                        value={line.personalTurnover}
                        postfix={Currency.USD}
                        decimals={2}
                        floor
                      />
                    </td>
                    <td>
                      <FormattedNumber
                        value={line.branchTurnover}
                        postfix={Currency.USD}
                        decimals={2}
                        floor
                      />
                    </td>
                    <td>
                      <FormattedNumber
                        value={line.wholeTurnover}
                        postfix={Currency.USD}
                        decimals={2}
                        floor
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
});

export default ReferralSubLines;
