import {action, makeAutoObservable, observable} from 'mobx';
import {ITokenBank, Language} from "../constants";
import {api} from "@helpers/api";

export class GlobalStore {
  @observable
  private _isAdmin: boolean = false;

  @observable
  private _language: Language = (localStorage.getItem('i18nextLng') as Language) || Language.EN;

  constructor() {
    makeAutoObservable(this);
  }

  get
  isAdmin() {
    return this._isAdmin;
  }

  setAdmin(value: boolean) {
    this._isAdmin = value;
  }

  setLanguage(value: Language) {
    this._language = value;
    localStorage.setItem('i18nextLng', value);
  }

  get
  language() {
    return this._language.toUpperCase() in Language ? this._language : Language.EN;
  }

  @action
  getTokenBank() {
    return api<ITokenBank>({method: 'get', path: '/token-bank'});
  }

  @action
  resetStore() {
  }
}
