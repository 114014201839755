import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";

import PromoTurkeyMay2023 from "@pages/Actions/actions/PromoTurkeyMay2023";

const Actions = observer(() => {
  const {t} = useTranslation();

  return (
    <div id="actions" className="container">
      <h1 className="mb-4">{t('actions.title')}</h1>

      <div className="row">
        <PromoTurkeyMay2023 />
      </div>
    </div>
  );
});

export default Actions;
