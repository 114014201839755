import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import type {ChangeEvent, MouseEvent} from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import Preloader from "@components/common/Preloader";
import {
  Currency,
  IGatInvestPlan,
  IInvestPlan,
  ITokenBank,
  IUsdInvestPlan,
  IWallet
} from "../../../constants";
import FormattedNumber from "@components/common/FormattedNumber";
import {convertStringToNumber} from "@helpers/bignumber";
import {mapCurrency} from "@helpers/mapCurrency";
import {getPercentage} from "@helpers/numbers";
import IconWithLoading from "@components/common/IconWithLoading";
import {faInfoCircle} from "@fortawesome/pro-duotone-svg-icons/faInfoCircle";
import {faSackDollar} from "@fortawesome/pro-duotone-svg-icons/faSackDollar";
import BigNumber from "bignumber.js";
import {isFuture, isPast} from "date-fns";
import FormattedDate from "@components/common/FormattedDate";
import {useTranslation} from "react-i18next";
import confirmAlert from "@components/ConfirmAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ModalAlert from "@components/ModalAlert";

const Invest = observer(() => {
  const {t} = useTranslation();
  const {globalStore, accountStore} = useStores();
  const handleResponse = useResponseHandler();

  const [investPlanAft, setInvestPlanAft] = useState<IInvestPlan>();
  const [investPlansGat, setInvestPlansGat] = useState<IGatInvestPlan[]>([]);
  const [investPlanGat, setInvestPlanGat] = useState<IGatInvestPlan>();
  const [investPlanUsd, setInvestPlanUsd] = useState<IUsdInvestPlan>();

  const [valueForAft, setValueForAft] = useState<number>();
  const [valueForGat, setValueForGat] = useState<number>();
  const [valueForUsdt, setValueForUsdt] = useState<number>();

  const [tokenBank, setTokenBank] = useState<ITokenBank>();
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [activeWalletForAft, setActiveWalletForAft] = useState<IWallet>();
  const [activeWalletForGat, setActiveWalletForGat] = useState<IWallet>();
  const [activeWalletForUsdt, setActiveWalletForUsdt] = useState<IWallet>();

  const [isLoading, setIsLoading] = useState(true);
  const [investLoading, setInvestLoading] = useState(false);
  const [walletsLoading, setWalletsLoading] = useState(false);

  const [isOpenAftInfoModal, setOpenAftInfoModal] = useState(false);
  const [isOpenGatInfoModal, setOpenGatInfoModal] = useState(false);
  const [isOpenUsdtInfoModal, setOpenUsdtInfoModal] = useState(false);

  const percentage = getPercentage(investPlanAft?.totalAufPaid || 0, investPlanAft?.maxAufProfit || 0) || 0;
  const aftWallets = wallets.filter((wallet) => wallet.currency !== Currency.GAT);

  const gatFrozenUntil = investPlanGat?.frozenUntil ? new Date(investPlanGat.frozenUntil) : null;
  const gatWorkUntil = investPlanGat?.workUntil ? new Date(investPlanGat.workUntil) : null;
  const usdWorkUntil = investPlanUsd?.workUntil ? new Date(investPlanUsd.workUntil) : null;
  const usdInvestAvailable = tokenBank && tokenBank.aufPrice > 0.1;

  useEffect(() => {
    globalStore.getTokenBank().then(setTokenBank);
  }, [globalStore]);

  const fetchInvestPlans = useCallback(() => {
    setIsLoading(true);
    Promise
      .all([accountStore.getInvestPlan(), accountStore.getGatInvestPlans(), accountStore.getUsdInvestPlan()])
      .then(([response, responseGat, responseUsd]) => {
        setInvestPlanAft(response);
        setInvestPlansGat(responseGat);
        setInvestPlanGat(responseGat[responseGat.length - 1]);
        setInvestPlanUsd(responseUsd);
        setIsLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setIsLoading(false);
      });
  }, [accountStore, handleResponse]);

  const fetchWallets = useCallback(() => {
    setWalletsLoading(true);
    accountStore.getWallets()
      .then((response) => {
        const priority = [Currency.USDT, Currency.AFT, Currency.GAT];
        const list = (response || []).slice().sort((a, b) => priority.indexOf(mapCurrency(a.currency)) - priority.indexOf(mapCurrency(b.currency)));
        setWallets(list);
        setActiveWalletForAft((prevState) => prevState ? list.find((wallet) => mapCurrency(wallet.currency) === mapCurrency(prevState.currency)) : list[0]);
        setActiveWalletForGat((prevState) => prevState ? list.find((wallet) => mapCurrency(wallet.currency) === mapCurrency(prevState.currency)) : list[0]);
        setActiveWalletForUsdt((prevState) => prevState ? list.find((wallet) => mapCurrency(wallet.currency) === mapCurrency(prevState.currency)) : list[0]);
        setWalletsLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setWalletsLoading(false);
      });
  }, [accountStore, handleResponse]);

  const handleInvest = useCallback(() => {
    if (!activeWalletForAft) {
      return;
    }

    if (!valueForAft || valueForAft < 0) {
      handleResponse(`${t('error.wrong-invest-amount')} ${Currency.AFT}`, true);
      return;
    }

    setInvestLoading(true);

    accountStore.invest(convertStringToNumber(valueForAft), activeWalletForAft.currency)
      .then((response) => {
        setInvestLoading(false);
        if (response.success) {
          setValueForAft(0);
          setIsLoading(true);
          fetchInvestPlans();
          fetchWallets();
          handleResponse(
            t('invest.success')
              .replace('%amount', valueForAft + '')
              .replace('%currency', mapCurrency(activeWalletForAft.currency))
              .replace('%invest',  Currency.AFT)
            ,
            false
          );
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data, true);
        setIsLoading(false);
        setInvestLoading(false);
      });
  }, [t, activeWalletForAft, accountStore, handleResponse, fetchWallets, fetchInvestPlans, valueForAft]);

  const handleInvestGat = useCallback(() => {
    if (!activeWalletForGat) {
      return;
    }

    if (!valueForGat || valueForGat < 0) {
      handleResponse(`${t('error.wrong-invest-amount')} ${Currency.GAT}`, true);
      return;
    }

    setInvestLoading(true);

    accountStore.investGat(convertStringToNumber(valueForGat), activeWalletForGat.currency)
      .then((response) => {
        setInvestLoading(false);
        if (response.success) {
          setValueForGat(0);
          setIsLoading(true);
          fetchInvestPlans();
          fetchWallets();
          handleResponse(
            t('invest.success')
              .replace('%amount', valueForGat + '')
              .replace('%currency', mapCurrency(activeWalletForGat.currency))
              .replace('%invest',  Currency.GAT)
            ,
            false
          );
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data, true);
        setIsLoading(false);
        setInvestLoading(false);
      });
  }, [t, activeWalletForGat, accountStore, handleResponse, fetchWallets, fetchInvestPlans, valueForGat]);

  const handleInvestUsdt = useCallback(() => {
    if (!activeWalletForUsdt) {
      return;
    }

    if (!valueForUsdt || valueForUsdt < 0) {
      handleResponse(`${t('error.wrong-invest-amount')} ${Currency.USDT}`, true);
      return;
    }

    setInvestLoading(true);

    accountStore.investUsd(convertStringToNumber(valueForUsdt), activeWalletForUsdt.currency)
      .then((response) => {
        setInvestLoading(false);
        if (response.success) {
          setValueForUsdt(0);
          setIsLoading(true);
          fetchInvestPlans();
          fetchWallets();
          handleResponse(
            t('invest.success')
              .replace('%amount', valueForUsdt + '')
              .replace('%currency', Currency.USDT)
              .replace('%invest',  Currency.USDT)
            ,
            false
          );
        } else {
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data, true);
        setIsLoading(false);
        setInvestLoading(false);
      });
  }, [t, activeWalletForUsdt, accountStore, handleResponse, fetchWallets, fetchInvestPlans, valueForUsdt]);

  const handleExitUsd = useCallback(() => {
    setInvestLoading(true);

    confirmAlert({
      title: t('invest.exit-title') + '',
      okLabel: t('invest.exit-ok') + '',
      okVariant: 'warning',
      size: 'lg',
      content: (
        <div className="tx-bold tx-warning">
          {t('invest.exit-confirmation')}
        </div>
      ),
    }).then((resp) => {
      if (!!resp) {
        accountStore.exitUsd()
          .then((response) => {
            setInvestLoading(false);
            if (response.success) {
              fetchInvestPlans();
              fetchWallets();
              handleResponse(t('invest.exit-success') + '', false);
            } else {
              handleResponse(response, true);
            }
          })
          .catch((response) => {
            handleResponse(response.response.data, true);
            setInvestLoading(false);
          });
      } else {
        setInvestLoading(false);
      }
    });
  }, [t, accountStore, handleResponse, fetchWallets, fetchInvestPlans]);

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  useEffect(() => {
    fetchInvestPlans();
  }, [fetchInvestPlans]);

  const openAftPlanInfo = useCallback(() => {
    setOpenAftInfoModal(true);
  }, []);

  const openGatPlanInfo = useCallback(() => {
    setOpenGatInfoModal(true);
  }, []);

  const openUsdPlanInfo = useCallback(() => {
    setOpenUsdtInfoModal(true);
  }, []);

  const handleAftValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValueForAft(event.target.valueAsNumber || undefined);
  }, []);

  const handleGatValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValueForGat(event.target.valueAsNumber || undefined);
  }, []);

  const handleUsdtValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValueForUsdt(event.target.valueAsNumber || undefined);
  }, []);

  const handleInvestPlanChange = useCallback((gatPlan: IGatInvestPlan) => (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setInvestPlanGat(gatPlan);
  }, []);

  const hasWorkingGatPlan = useMemo(() => {
    if (investPlansGat.length < 1) {
      return false;
    }
    let hasWorkingPlan = false;
    investPlansGat.forEach((gatPlan) => {
      if (isPast(new Date(gatPlan.frozenUntil)) && isFuture(new Date(gatPlan.workUntil))) {
        hasWorkingPlan = true;
      }
    });
    return hasWorkingPlan;
  }, [investPlansGat]);

  return (
    <div id="invest" className="container">
      <h1 className="mb-4">{t('invest.title')}</h1>

      <div className="row">
        <div className="col-12 col-lg-6 mb-4">
          <div className="card p-0 bg-dark">
            <div className="card-heading d-flex justify-content-between align-items-center mb-3 mt-3 mx-4 ms-5">
              <div className="notranslate d-flex align-items-center tx-24 mb-0">
                {Currency.AFT}
              </div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="tx-primary me-2 interactive"
                size="2x"
                onClick={openAftPlanInfo}
                title={`${t('common.information')}`}
              />
            </div>

            <div className="card ht-100p gradient-primary">
              <div className="card-body">
                {isLoading && !investPlanAft && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
                {investPlanAft && (
                  <div>
                    <div className="text-center tx-bold tx-italic mb-4">
                      {t('invest.aft-plan.intro')}
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                      <div className="">
                        <div className="mb-3">
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.invested')}</div>
                          <FormattedNumber
                            value={investPlanAft.totalAufInvested}
                            postfix={Currency.AFT}
                            className="me-3"
                          />
                          <FormattedNumber
                            value={investPlanAft.totalUsdInvested}
                            postfix={Currency.USDT}
                          />
                        </div>
                        <div>
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.stake-balance')}</div>
                          <FormattedNumber
                            value={investPlanAft.aufInvestmentBody}
                            postfix={Currency.AFT}
                            className="d-block mb-2"
                          />
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.paid')}</div>
                          <FormattedNumber
                            value={investPlanAft.paidAufPassive + investPlanAft.paidAufRef}
                            postfix={Currency.AFT}
                          />
                        </div>
                      </div>
                      <div className="mt-3 mt-md-0 wd-100p wd-md-100 tx-left tx-md-center ms-0 ms-md-3">
                        {/*{!!investPlanAft.maxAufProfit && (*/}
                        {/*  <div className="d-flex justify-content-between">*/}
                        {/*    <div>0 {Currency.AFT}</div>*/}
                        {/*    <div>{investPlanAft.maxAufProfit} {Currency.AFT}</div>*/}
                        {/*  </div>*/}
                        {/*)}*/}
                        <div className="progress d-flex d-md-none">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={percentage}
                            aria-valuemin={0}
                            aria-valuemax={investPlanAft.maxAufProfit}
                            style={{width: `${percentage}%`}}
                          />
                        </div>
                        <div className="progress progress-vertical d-none d-md-flex mx-auto">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            aria-valuenow={percentage}
                            aria-valuemin={0}
                            aria-valuemax={investPlanAft.maxAufProfit}
                            style={{height: `${percentage}%`}}
                          />
                        </div>
                        {/*<div className="tx-center">*/}
                        {/*  <FormattedNumber*/}
                        {/*    valueForAft={investPlanAft?.totalAufPaid}*/}
                        {/*    postfix={Currency.AFT}*/}
                        {/*    decimals={2}*/}
                        {/*    floor*/}
                        {/*  />*/}
                        {/*</div>*/}
                        <div className="tx-uppercase tx-bold tx-spacing-1 tx-12 mt-1">{t('invest.x3-factor')}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 mb-4">
          <div className="card p-0 bg-dark ht-100p">
            {aftWallets.length > 0 && (
              <div className="card-heading d-flex justify-content-between mb-3 mt-3 ms-5">
                <ul className="nav justify-content-center justify-content-md-start">
                  {aftWallets.map((wallet) => (
                    <li key={`invest-wallet-tab-${wallet.currency}`} className="nav-item">
                      <button
                        type="button"
                        className={`notranslate nav-link lh-1 tx-24 btn btn-link p-0 me-3 ${activeWalletForAft?.currency === wallet.currency ? 'active tx-bold text-decoration-none' : 'tx-gray-600'}`}
                        onClick={() => {
                          setActiveWalletForAft(wallet);
                          setValueForAft(undefined);
                        }}
                      >
                        {mapCurrency(wallet.currency)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="card tx-center tx-md-left ht-100p gradient-primary">
              <div className="card-body d-md-flex flex-column justify-content-between ht-100p">
                {activeWalletForAft && (
                  <div className="d-md-flex flex-column justify-content-between ht-100p">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0.00"
                        value={valueForAft}
                        min={0}
                        id="valueForAft"
                        onChange={handleAftValueChange}
                        disabled={investLoading}
                      />
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-md-start align-items-md-center mt-md-3 mt-lg-auto mb-0">
                      <button type="button" className="notranslate btn btn-black tx-primary mt-2 mt-lg-0 order-2 order-md-1" onClick={handleInvest}>
                        <IconWithLoading
                          icon={faSackDollar}
                          isLoading={investLoading}
                          className="me-3"
                        />
                        {t('invest.invest-into')} {Currency.AFT}
                      </button>
                      <div className="ms-md-3 order-1 order-md-2 mt-3 mt-md-0">
                        {t('common.available')}:
                        <FormattedNumber
                          value={activeWalletForAft.balance}
                          postfix={mapCurrency(activeWalletForAft.currency)}
                          decimals={2}
                          floor
                          className="text-decoration-underline ms-1 cur-pointer"
                          onClick={() => setValueForAft(new BigNumber(activeWalletForAft.balance).decimalPlaces(2, 1).toNumber())}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {wallets.length > 0 && walletsLoading && (
                  <Preloader />
                )}
                {!wallets.length && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 mb-4">
          <div className="card p-0 bg-dark">
            <div className="card-heading d-flex justify-content-between align-items-center mb-3 mt-3 mx-4 ms-5">
              <div className="d-flex align-items-center tx-24">
                <div className="notranslate">{Currency.GAT}</div>
                {investPlansGat.length > 1 && (
                  <ul className="nav justify-content-between my-0 px-5 nav-levels invest-plans">
                    {investPlansGat.map((gatPlan, index) => (
                      <li key={gatPlan.id} className="nav-item tx-16">
                        <button
                          type="button"
                          className={`nav-link btn btn-link p-0 ${investPlanGat?.id === gatPlan.id ? 'active' : 'tx-gray-600'}`}
                          onClick={handleInvestPlanChange(gatPlan)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="tx-primary me-2 interactive"
                size="2x"
                onClick={openGatPlanInfo}
                title={`${t('common.information')}`}
              />
            </div>

            <div className="card ht-100p gradient-primary">
              <div className="card-body">
                {isLoading && !investPlanGat && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
                {investPlanGat && (
                  <div>
                    <div className="text-center tx-bold tx-italic mb-4">
                      {t('invest.gat-plan.intro')}
                    </div>
                    <div className="row ht-100p">
                      <div className="col-md-6">
                        <div>
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.invested')}</div>
                          <FormattedNumber
                            value={investPlanGat.totalGatInvested}
                            postfix={Currency.GAT}
                            className="d-inline text-nowrap me-2"
                          />
                          <FormattedNumber
                            value={investPlanGat.totalAufInvested}
                            postfix={Currency.AFT}
                            className="d-inline text-nowrap me-2"
                          />
                          <FormattedNumber
                            value={investPlanGat.totalUsdInvested}
                            postfix={Currency.USDT}
                            className="d-inline text-nowrap"
                          />
                        </div>
                        <div className="mt-2">
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.stake-balance')}</div>
                          <FormattedNumber
                            value={investPlanGat.gatInvestmentBody}
                            postfix={Currency.GAT}
                            className="d-block"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-2 mt-md-0">
                        {gatFrozenUntil && isFuture(gatFrozenUntil) && (
                          <div>
                            <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.frozen-until')}</div>
                            <FormattedDate
                              date={gatFrozenUntil}
                              dateFormat="dd.MM.yyyy"
                            />
                          </div>
                        )}
                        {gatFrozenUntil && isPast(gatFrozenUntil) && gatWorkUntil && isFuture(gatWorkUntil) && (
                          <>
                            <div>
                              <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.work-until')}</div>
                              <FormattedDate
                                date={gatWorkUntil}
                                dateFormat="dd.MM.yyyy"
                              />
                            </div>
                            <div className="mt-2">
                              <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.paid')}</div>
                              <FormattedNumber
                                value={investPlanGat.paidGatPassive}
                                postfix={Currency.GAT}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {hasWorkingGatPlan && (
                      <div className="text-center tx-bold mt-4">
                        {t('invest.coin-weight-days')}: {investPlanGat.gatWeightDaysCount}
                      </div>
                    )}
                  </div>
                )}
                {!investPlanGat && !isLoading && (
                  <div>
                    <div className="text-center tx-bold tx-italic mb-4">
                      {t('invest.gat-plan.intro')}
                    </div>
                    <div className="row ht-100p">
                      <div className="col-md-6">
                        <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.invested')}</div>
                        <FormattedNumber
                          value="0"
                          postfix={Currency.GAT}
                          className="d-inline text-nowrap me-2"
                        />
                        <FormattedNumber
                          value="0"
                          postfix={Currency.AFT}
                          className="d-inline text-nowrap me-2"
                        />
                        <FormattedNumber
                          value="0"
                          postfix={Currency.USDT}
                          className="d-inline text-nowrap"
                        />
                      </div>
                      <div className="col-md-6 mt-2 mt-md-0">
                        <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.stake-balance')}</div>
                        <FormattedNumber
                          value="0"
                          postfix={Currency.GAT}
                          className="d-block mb-2"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 mb-4">
          <div className="card p-0 bg-dark ht-100p">
            {wallets.length > 0 && (
              <div className="card-heading d-flex justify-content-between mb-3 mt-3 ms-5">
                <ul className="nav justify-content-center justify-content-md-start">
                  {wallets.map((wallet) => (
                    <li key={`invest-wallet-tab-${wallet.currency}`} className="nav-item">
                      <button
                        type="button"
                        className={`notranslate nav-link lh-1 tx-24 btn btn-link p-0 me-3 ${activeWalletForGat?.currency === wallet.currency ? 'active tx-bold text-decoration-none' : 'tx-gray-600'}`}
                        onClick={() => {
                          setActiveWalletForGat(wallet);
                          setValueForGat(undefined);
                        }}
                      >
                        {mapCurrency(wallet.currency)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="card tx-center tx-md-left ht-100p gradient-primary">
              <div className="card-body d-md-flex flex-column d-md-flex flex-column justify-content-center ht-100p ht-100p">
                {activeWalletForGat && (
                  <div className="d-md-flex flex-column justify-content-between ht-100p">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0.00"
                        value={valueForGat}
                        min={0}
                        id="valueForGat"
                        onChange={handleGatValueChange}
                        disabled={investLoading}
                      />
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-md-start align-items-md-center mt-md-3 mt-lg-auto mb-0">
                      <button type="button" className="notranslate btn btn-black tx-primary mt-2 mt-lg-0 order-2 order-md-1" onClick={handleInvestGat}>
                        <IconWithLoading
                          icon={faSackDollar}
                          isLoading={investLoading}
                        />
                        {t('invest.invest-into')} {Currency.GAT}
                      </button>
                      <div className="ms-md-3 order-1 order-md-2 mt-3 mt-md-0">
                        {t('common.available')}:
                        <FormattedNumber
                          value={activeWalletForGat.balance}
                          postfix={mapCurrency(activeWalletForGat.currency)}
                          decimals={2}
                          floor
                          className="text-decoration-underline ms-1 cur-pointer"
                          onClick={() => setValueForGat(new BigNumber(activeWalletForGat.balance).decimalPlaces(2, 1).toNumber())}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {wallets.length > 0 && walletsLoading && (
                  <Preloader />
                )}
                {!wallets.length && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 mb-4">
          <div className="card p-0 bg-dark">
            <div className="card-heading d-flex justify-content-between align-items-center mb-3 mt-3 mx-4 ms-5">
              <div className="notranslate d-flex align-items-center tx-24">
                SWAP
              </div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="tx-primary me-2 interactive"
                size="2x"
                onClick={openUsdPlanInfo}
                title={`${t('common.information')}`}
              />
            </div>

            <div className="card ht-100p gradient-primary">
              <div className="card-body">
                {isLoading && !investPlanUsd && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
                {investPlanUsd && (
                  <div>
                    <div className="text-center tx-bold tx-italic mb-4">
                      {t('invest.swap-plan.intro')}
                    </div>
                    <div className="row ht-100p">
                      <div className="col-md-6">
                        <div className="mb-2">
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.invested')}</div>
                          <FormattedNumber
                            value={investPlanUsd.totalUsdInvested}
                            postfix={Currency.USDT}
                            className="d-block"
                          />
                        </div>
                        <div>
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.stake-balance')}</div>
                          <FormattedNumber
                            value={investPlanUsd.usdInvestmentBody}
                            postfix={Currency.USDT}
                            className="d-block"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 mt-2 mt-md-0">
                        {usdWorkUntil && isFuture(usdWorkUntil) && (
                          <div className="mb-2">
                            <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.work-until')}</div>
                            <FormattedDate
                              date={usdWorkUntil}
                              dateFormat="dd.MM.yyyy"
                            />
                          </div>
                        )}
                        <div>
                          <div className="tx-uppercase tx-bold tx-spacing-1">{t('invest.paid')}</div>
                          <FormattedNumber
                            value={investPlanUsd.paidUsdPassive}
                            postfix={Currency.USDT}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6 mb-4">
          <div className="card p-0 bg-dark ht-100p">
            {wallets.length > 0 && (
              <div className="card-heading d-flex justify-content-between align-items-center mb-3 mt-3 mx-4 ms-5">
                <div className="notranslate justify-content-center justify-content-md-start tx-24 tx-bold">
                  {Currency.USDT}
                </div>
                {usdInvestAvailable && (
                  <button
                    type="button"
                    className="btn btn-sm btn-warning"
                    onClick={handleExitUsd}
                    disabled={!usdWorkUntil || !isFuture(usdWorkUntil)}
                  >
                    {t('invest.exit')}
                  </button>
                )}
              </div>
            )}

            <div className="card tx-center tx-md-left ht-100p gradient-primary">
              <div className="card-body d-md-flex flex-column d-md-flex flex-column justify-content-center ht-100p ht-100p">
                {tokenBank && !usdInvestAvailable && (
                  <div className="tx-bold tx-18">{t('invest.swap-unavailable')}</div>
                )}
                {usdInvestAvailable && activeWalletForUsdt && (
                  <div className="d-md-flex flex-column justify-content-between ht-100p">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0.00"
                        value={valueForUsdt}
                        min={0}
                        id="valueForUsdt"
                        onChange={handleUsdtValueChange}
                        disabled={investLoading}
                      />
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-md-start align-items-md-center mt-md-3 mt-lg-auto mb-0">
                      <button type="button" className="notranslate btn btn-black tx-primary mt-2 mt-lg-0 order-2 order-md-1" onClick={handleInvestUsdt}>
                        <IconWithLoading
                          icon={faSackDollar}
                          isLoading={investLoading}
                        />
                        {t('invest.invest-into')} {Currency.USDT}
                      </button>
                      <div className="ms-md-3 order-1 order-md-2 mt-3 mt-md-0">
                        {t('common.available')}:
                        <FormattedNumber
                          value={activeWalletForUsdt.balance}
                          postfix={Currency.USDT}
                          decimals={2}
                          floor
                          className="text-decoration-underline ms-1 cur-pointer"
                          onClick={() => setValueForUsdt(new BigNumber(activeWalletForUsdt.balance).decimalPlaces(2, 1).toNumber())}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {wallets.length > 0 && walletsLoading && (
                  <Preloader />
                )}
                {!wallets.length && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalAlert
        key="aft-info"
        proceed={setOpenAftInfoModal}
        show={isOpenAftInfoModal}
        title={`${t('invest.aft-plan.title')}`}
        cancelLabel={`${t('common.close')}`}
        size="lg"
        noOk
        enableEscape
        content={(
          <>
            <p>{t('invest.aft-plan.text.1')}</p>
            <p>{t('invest.aft-plan.text.2')}</p>
            <p>{t('invest.aft-plan.text.3')}</p>
            <p>{t('invest.aft-plan.text.4')}</p>
            <p>{t('invest.aft-plan.text.5')}</p>
            <p>{t('invest.aft-plan.text.6')}</p>
            <p>{t('invest.aft-plan.text.7')}</p>
            <p>{t('invest.aft-plan.text.8')}</p>
            <p>{t('invest.aft-plan.text.9')}</p>
            <div className="mt-5">
              <div className="tx-bold tx-20">{t('common.attention')}:</div>
              <div className="tx-italic">{t('invest.aft-plan.text.attention')}</div>
            </div>
          </>
        )}
      />

      <ModalAlert
        key="gat-info"
        proceed={setOpenGatInfoModal}
        show={isOpenGatInfoModal}
        title={`${t('invest.gat-plan.title')}`}
        cancelLabel={`${t('common.close')}`}
        size="lg"
        noOk
        enableEscape
        content={(
          <>
            <p>{t('invest.gat-plan.text.1')}</p>
            <p>{t('invest.gat-plan.text.2')}</p>
            <p>{t('invest.gat-plan.text.3')}</p>
            <p>{t('invest.gat-plan.text.4')}</p>
            <p>{t('invest.gat-plan.text.5')}</p>
            <p>{t('invest.gat-plan.text.6')}</p>
            <p>{t('invest.gat-plan.text.7')}</p>
            <p>{t('invest.gat-plan.text.8')}</p>
            <p>{t('invest.gat-plan.text.9')}</p>
            <p>{t('invest.gat-plan.text.10')}</p>
            <p>{t('invest.gat-plan.text.11')}</p>
          </>
        )}
      />

      <ModalAlert
        key="swap-info"
        proceed={setOpenUsdtInfoModal}
        show={isOpenUsdtInfoModal}
        title={`${t('invest.swap-plan.title')}`}
        cancelLabel={`${t('common.close')}`}
        size="lg"
        noOk
        enableEscape
        content={(
          <>
            <p>{t('invest.swap-plan.text.1')}</p>
            <p>{t('invest.swap-plan.text.2')}</p>
            <p>{t('invest.swap-plan.text.3')}</p>
            <p>{t('invest.swap-plan.text.4')}</p>
            <p>{t('invest.swap-plan.text.5')}</p>
            <p>{t('invest.swap-plan.text.6')}</p>
            <p>{t('invest.swap-plan.text.7')}</p>
            <p>{t('invest.swap-plan.text.8')}</p>
            <p>{t('invest.swap-plan.text.9')}</p>
            <p>{t('invest.swap-plan.text.10')}</p>
            <p>{t('invest.swap-plan.text.11')}</p>
          </>
        )}
      />
    </div>
  )
});

export default Invest;
