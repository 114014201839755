import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import doc1 from '@assets/images/documents/doc1.jpeg';
import doc2 from '@assets/images/documents/doc2.jpeg';
import doc3 from '@assets/images/documents/doc3.jpeg';
import doc4 from '@assets/images/documents/doc4.jpeg';
import doc5 from '@assets/images/documents/doc5.jpeg';
import doc6 from '@assets/images/documents/doc6.jpeg';
import doc7 from '@assets/images/documents/doc7.jpeg';
import doc8 from '@assets/images/documents/doc8.jpeg';
import docEco from '@assets/images/documents/doc-eco.jpg';
import docNew from '@assets/images/documents/doc-new.jpg';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

export const Documents = () => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const documents = useMemo(
    () => [
      { src: doc1 },
      { src: doc2 },
      { src: docNew },
      { src: doc3 },
      { src: doc4 },
      { src: doc5 },
      { src: doc6 },
      { src: doc7 },
      { src: doc8 },
      { src: docEco },
    ],
    []
  );

  return (
    <div>
      <button className="btn btn-link p-0" onClick={() => setOpen(true)}>
        {t('footer.documents')}
      </button>

      <Lightbox
        open={isOpen}
        close={() => setOpen(false)}
        slides={documents}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
    </div>
  );
};
