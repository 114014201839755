import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {faKey} from "@fortawesome/pro-regular-svg-icons/faKey";

import IconWithLoading from "@components/common/IconWithLoading";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";

import {usernameRx} from "../../../../constants";

export const PasswordResetRequest = () => {
  const {t} = useTranslation();

  const {accountStore} = useStores();
  const handleResponse = useResponseHandler();

  const [isRequestSent, setRequestSent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [login, setLogin] = useState('');

  const isLoginValid = useCallback(() => {
    if (!login) {
      handleResponse(t('error.empty-login') + '', true);
      return false;
    }

    if (login.length < 4 || !usernameRx.test(login)) {
      handleResponse(t('error.wrong-login') + '', true);
      return false;
    }

    return true;
  }, [login, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (!isLoginValid()) {
      return;
    }

    setLoading(true);
    accountStore.resetPasswordToken(login)
      .then((response) => {
        if (response.success) {
          setRequestSent(true);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [accountStore, login, isLoginValid, handleResponse]);

  if (isRequestSent) {
    return (
      <div className="card-body">
        <div id="request-sent" className="wd-100p wd-md-500">
          <h5 className="card-title tx-18 mx-3 mb-4">{t('reset-password.success-request')}</h5>
        </div>
      </div>
    );
  }

  return (
    <div className="card-body">
      <form
        id="login"
        className="flex-1"
        onSubmit={(e) => {e.preventDefault(); submitForm();}}
      >
        <div id="reset-request-form" className="wd-100p wd-md-500">
          <h5 className="card-title tx-16 mx-3 mb-4">{t('reset-password.help-text')}</h5>
          <div className="ms-3 tx-18 mb-1">{t('common.login-field')}</div>
          <div className="form-group">
            <input
              type="text"
              className="form-control form-control-lg mb-2"
              placeholder={t('common.login-field') + ''}
              value={login}
              id="login"
              onChange={(event) => {
                setLogin(event.target.value);
              }}
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <button
              className="btn btn-black tx-primary wd-100p"
              type="submit"
              disabled={isLoading}
            >
              <IconWithLoading
                icon={faKey}
                isLoading={isLoading}
              />
              {t('reset-password.title')}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
};
