import {useStores} from "./useStores";
import {useCallback} from "react";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export interface Error {
  code: string;
  message: string;
}

export interface ServerResponse<T> {
  success: boolean;
  data?: T | null;
  errors?: Array<Error> | null;
}

export interface StatusResponse<T> extends ServerResponse<T> {
  status?: number;
  statusCodeValue?: number;
  statusCode?: string;
  body: any;
}

export default function useResponseHandler() {
  const {accountStore} = useStores();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  return useCallback((response?: StatusResponse<any> | string, isError?: boolean) => {
    const toastOptions: { duration: number, id?: string } = {duration: 5000};
    let redirect = false;
    let isSessionExpired = false;
    let messages: string[] = [];

    // console.log('useResponseHandler', {response});
    if (response && typeof response !== 'string') {
      if (response.errors?.length) {
        isError = true;
        messages = response.errors.map((error) => i18n.exists(error.code) ? t(error.code) : error.message);
      }
      // console.log({messages});
      if (response.statusCodeValue === 401 || response.statusCodeValue === 403 || response.statusCode === 'UNAUTHORIZED') {
        isError = true;
        messages.push(t('error.session-expired'));
        isSessionExpired = true;
        accountStore.setLoggedIn(false);
      }
      if (!isError && response.status !== 200) {
        isError = true;
      }
    } else if (typeof response === 'string') {
      isError = isError || response.toLocaleLowerCase().indexOf('error') > -1;
      messages.push(response);
    }

    if (isSessionExpired) {
      toastOptions.id = 'session-expired';
    }

    if (isError && !messages.length) {
      messages.push(t('error.default'));
    }

    if (isError) {
      if (!redirect) {
        messages.forEach((message) => {
          toast.error(message, toastOptions);
        });
      } else {
        navigate('/error', {state: {messages}});
      }
    } else {
      messages.forEach((message) => {
        toast.success(message, toastOptions);
      });
    }
  }, [t, i18n, accountStore, navigate]);
}
