import {observer} from "mobx-react-lite";
import {useCallback} from 'react';
import {Dropdown} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Flag from 'react-world-flags';

import {LanguageMeta} from "@app-types/types";
import {useStores} from "@hooks/useStores";

import {Language} from "../../../constants";

export interface LanguageSwitcherButtonProperties {
  languageMeta: LanguageMeta;
  onClick?: () => void;
}

export const LanguageSwitcherButton = observer(({languageMeta, onClick}: LanguageSwitcherButtonProperties) => {
  const {globalStore} = useStores();
  const {i18n} = useTranslation();

  const changeLanguage = useCallback((lang: Language) => {
    globalStore.setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [i18n, globalStore]);

  return (
    <Dropdown.Item
      eventKey={languageMeta.id}
      active={globalStore.language === languageMeta.lang}
      onClick={() => {
        changeLanguage(languageMeta.id);
        if (onClick) {
          onClick();
        }
      }}
      className="notranslate"
    >
      <Flag code={languageMeta.flag} width="20" height="16" className="me-3" />
      {languageMeta.name}
    </Dropdown.Item>
  );
});
