import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";

import {faRightToBracket} from "@fortawesome/pro-duotone-svg-icons/faRightToBracket";

import IconWithLoading from "@components/common/IconWithLoading";
import useResponseHandler from "@hooks/useResponseHandler";
import {useStores} from "@hooks/useStores";

import {usernameRx, PageRoutes} from "../../../constants";

export default function Login() {
  const {t} = useTranslation();
  const {accountStore} = useStores();
  const handleResponse = useResponseHandler();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const isLoginAndPasswordValid = useCallback(() => {
    if (!login) {
      handleResponse(t('error.empty-login') + '', true);
      return false;
    }

    if (login.length < 4 || !usernameRx.test(login)) {
      handleResponse(t('error.wrong-login') + '', true);
      return false;
    }

    if (!password) {
      handleResponse(t('error.empty-password') + '', true);
      return false;
    }

    if (password.length < 8 || password.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return false;
    }

    return true;
  }, [login, password, handleResponse, t]);

  const submitForm = useCallback(() => {
    if (!isLoginAndPasswordValid()) {
      return;
    }

    setLoading(true);
    accountStore.login(login, password)
      .then((response) => {
        if (response.success) {
          navigate(PageRoutes.ROOT);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [accountStore, navigate, login, password, isLoginAndPasswordValid, handleResponse]);

  return (
    <div className="container">
      <div className="card p-0 bg-dark">
        <h2 className="mb-3 mt-3 ms-5 tx-24">{t('login.title')}</h2>

        <div className="card ht-100p gradient-primary">
          <div className="card-body">
            <form
              id="login"
              className="flex-1"
              onSubmit={(e) => {e.preventDefault(); submitForm();}}
            >
              <div>
                <div id="login-form" className="wd-100p wd-md-500">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control form-control-lg mb-2"
                      placeholder={t('common.login-field') + ''}
                      value={login}
                      id="login"
                      onChange={(event) => {
                        setLogin(event.target.value);
                      }}
                      disabled={isLoading}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control form-control-lg mb-2"
                      placeholder={t('common.password') + ''}
                      value={password}
                      id="password"
                      onChange={(event) => setPassword(event.target.value)}
                      disabled={isLoading}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-black tx-primary wd-100p"
                      type="submit"
                      disabled={isLoading}
                    >
                      <IconWithLoading icon={faRightToBracket} className="me-2" isLoading={isLoading} />
                      {t('common.login')}
                    </button>
                  </div>

                  <div className="mt-2">
                    {t('login.forgot-password')} <Link to={PageRoutes.RESET_PASSWORD}>{t('login.restore-password')}</Link>
                  </div>

                  <div className="mt-2">
                    {t('login.no-account')} <Link to={PageRoutes.REGISTER}>{t('common.register')}</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
