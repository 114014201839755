import {Currency} from "../constants";

const currencyMap: Record<string, Currency> = {
  [Currency.USD]: Currency.USDT,
  [Currency.AUF]: Currency.AFT,
}

export function mapCurrency(currency: Currency) {
  return  currencyMap[currency] || currency;
}
