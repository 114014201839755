import React from "react";
import CopyButton from "@components/common/CopyButton";
import {ITransaction, ITransactionType} from "../../../../constants";
import {useTranslation} from "react-i18next";

export interface TransactionDetailsProps {
  entry: ITransaction;
}

export default function TransactionDetails({entry}: TransactionDetailsProps) {
  const {t} = useTranslation();
  let element;
  // let prefix;
  let withCopy = false;

  if (entry.type === ITransactionType.WITHDRAWAL) {
    element = entry.metadata?.withdrawalConfirmed
      ? (
        <div>
          <div>
            <span className="me-1">{t('common.hash')}:</span>
            <CopyButton
              text={entry.metadata.transactionHash}
              element={
                entry.metadata.transactionHash.length > 12
                  ? `${entry.metadata.transactionHash.slice(0, 5)}...${entry.metadata.transactionHash.slice(-4)}`
                  : entry.metadata.transactionHash
              }
            />
          </div>
          {entry.metadata.withdrawalAddress && (
            <div>
              <span className="me-1">{t('common.address')}:</span>
              <CopyButton
                text={entry.metadata.withdrawalAddress}
                element={`${entry.metadata.withdrawalAddress.slice(0, 5)}...${entry.metadata.withdrawalAddress.slice(-4)}`}
              />
            </div>
          )}
        </div>
      )
      : (
        <div>
          <div>{t('transactions.withdrawal-pending')}</div>
          {entry.metadata.withdrawalAddress && (
            <div>
              <span className="me-1">{t('common.address')}:</span>
              <CopyButton
                text={entry.metadata.withdrawalAddress}
                element={`${entry.metadata.withdrawalAddress.slice(0, 5)}...${entry.metadata.withdrawalAddress.slice(-4)}`}
              />
            </div>
          )}
        </div>
      )
  }
  //
  // if (entry.type === TransactionType.WITHDRAWAL) {
  //   element = entry.metadata?.withdrawalAddress;
  //   prefix = t('common.address');
  //   withCopy = true;
  // }
  //
  // if (entry.type === TransactionType.BUY_CONTRACT) {
  //   element = entry.metadata?.contractTemplateName;
  //   prefix = t('common.contract-template-name');
  // }
  //
  // if (entry.type === TransactionType.REFERRAL_REWARD) {
  //   element =
  //     t('transaction.details.referral-reward')
  //       .replace('%line', entry.metadata?.rewardLine + '')
  //       .replace('%usd', entry.metadata?.usdBuyPrice + '');
  // }

  if (element) {
    return (
      <span className="transaction-details word-break">
        {/*{!!prefix && (
          <div className="tx-semibold">
            {prefix}:
          </div>
        )}*/}
        {withCopy && typeof(element) === 'string' ? <CopyButton text={element}/> : <>{element}</>}
      </span>
    )
  }

  return null;
}
