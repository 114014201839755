import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/pro-solid-svg-icons";
import {observer} from "mobx-react-lite";
import {useMemo} from 'react';
import {Dropdown} from "react-bootstrap";

import {LanguageMeta} from "@app-types/types";
import {useStores} from "@hooks/useStores";

import {Language} from "../../../constants";

import {LanguageSwitcherButton} from "./LanguageSwitcherButton";

export interface LanguageSwitcherProps {
  className?: string;
  onClick?: () => void;
}

const languageMetaMap: Record<Language, LanguageMeta> = {
  [Language.RU]: { id: Language.RU, lang: Language.RU, flag: Language.RU, name: 'Русский' },
  [Language.EN]: { id: Language.EN, lang: Language.EN, flag: 'us', name: 'English' },
  [Language.ES]: { id: Language.ES, lang: Language.ES, flag: Language.ES, name: 'Español' },
  [Language.FR]: { id: Language.FR, lang: Language.FR, flag: Language.FR, name: 'Français' },
  [Language.IT]: { id: Language.IT, lang: Language.IT, flag: 'IT', name: 'Italiano' },
}

export const LanguageSwitcher = observer(({className, onClick}: LanguageSwitcherProps) => {
  const {globalStore} = useStores();

  const selectedLanguage = useMemo(() => {
    return languageMetaMap[globalStore.language];
  }, [globalStore.language]);

  return (
    <div className={'language-switcher d-flex justify-content-center ' + className}>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-language"
          key="dropdown-language"
          variant="transparent"
          className="notranslate pl-0 pl-md-2 pr-2 my-3 my-lg-0"
        >
          <FontAwesomeIcon icon={faGlobe} className="me-2"/>
          {selectedLanguage.lang}
        </Dropdown.Toggle>

        <Dropdown.Menu className="language-switcher-list">
          {Object.entries(Language).map(([key, lang]) => (
            <LanguageSwitcherButton languageMeta={languageMetaMap[lang]} key={key} onClick={onClick} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
});
