import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {observer} from "mobx-react-lite";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import QRCode from "qrcode.react";

import {faLayerPlus} from "@fortawesome/pro-regular-svg-icons/faLayerPlus";
import {faArrowsRotate} from "@fortawesome/pro-regular-svg-icons/faArrowsRotate";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";

import CopyButton from "@components/common/CopyButton";
import IconWithLoading from "@components/common/IconWithLoading";
import Preloader from "@components/common/Preloader";
import useResponseHandler from "@hooks/useResponseHandler";
import {useStores} from "@hooks/useStores";

import {CryptoTicker, IDepositOption} from "../../../constants";

const cryptoTicketMap: Record<CryptoTicker, string> = {
  [CryptoTicker.BTC]: 'BTC',
  [CryptoTicker.ETH]: 'ETH',
  [CryptoTicker.ERC_USDT]: 'USDT (ERC-20)',
  [CryptoTicker.TRC_USDT]: 'USDT (TRC-20)',
}

const depositWallets: CryptoTicker[] = [CryptoTicker.BTC, CryptoTicker.ETH, CryptoTicker.ERC_USDT, CryptoTicker.TRC_USDT];
const networks = ['bitcoin', 'ethereum (erc-20)', 'ethereum (erc-20)', 'tron (trc-20)'];

const Deposit = observer(() => {
  const {t} = useTranslation();
  const {accountStore} = useStores();
  const handleResponse = useResponseHandler();
  const [activeTicker, setActiveTicker] = useState<CryptoTicker>(depositWallets[0]);
  const [activeNetwork, setActiveNetwork] = useState<string>(networks[0]);
  const [depositOptions, setDepositOptions] = useState<IDepositOption[]>([]);
  const currentOption = depositOptions.find((option) => option.cryptocurrency === activeTicker);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchOptions = useCallback(() => {
    setIsLoading(true);
    accountStore.getDepositOptions()
      .then((response) => {
        setDepositOptions(response);
        setIsLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setIsLoading(false);
        setIsError(true);
      });
  }, [accountStore, handleResponse]);

  const createOption = useCallback(() => {
    if (!activeTicker) {
      return;
    }

    setIsLoading(true);

    accountStore.createDepositOption(activeTicker)
      .then(() => {
        fetchOptions();
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setIsLoading(false);
        setIsError(true);
      });
  }, [accountStore, handleResponse, fetchOptions, activeTicker]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  useEffect(() => {
    setIsInit(true);
  }, [depositOptions]);

  return (
    <div id="deposit" className="card gradient-primary-down pb-5">
      <ul className="nav mb-5 justify-content-center justify-content-md-start">
        {depositWallets.map((ticker, index) => (
          <li key={ticker} className="nav-item">
            <button
              className={`notranslate nav-link btn btn-link px-0 mx-3 pt-0 pb-1 ${activeTicker === ticker && 'active'}`}
              onClick={() => {
                setActiveTicker(ticker);
                setActiveNetwork(networks[index]);
              }}
            >
              {cryptoTicketMap[ticker]}
            </button>
          </li>
        ))}
      </ul>

      {currentOption && (
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">
          <div>
            <h5 className="card-title tx-18">{t('deposit.personal-address')} {activeTicker && cryptoTicketMap[activeTicker]}</h5>
            <p className="card-text mb-0 tx-bold tx-20">
              <FontAwesomeIcon icon={faExclamationCircle} className="me-1"/>
              {t('common.network')} <span className="tx-uppercase">{activeNetwork}</span>
            </p>
            <p className="card-text mb-0 tx-bold tx-20">
              <FontAwesomeIcon icon={faExclamationCircle} className="me-1"/>
              {t('deposit.fee-note')}
            </p>
            <CopyButton
              text={currentOption.address}
              element={currentOption.address}
              className="btn btn-link tx-white px-0 word-break"
              iconClass="tx-white tx-14 me-2"
            />
          </div>
          <div className="wd-175 mb-5 mt-md-0 ms-md-3">
            <QRCode value={currentOption.address} size={175} bgColor="#000" fgColor="#AA7600" includeMargin/>
          </div>
        </div>
      )}
      {!isError && isInit && !currentOption && (
        <div>
          <h5 className="card-title">{t('deposit.no-address')} {activeTicker && cryptoTicketMap[activeTicker]}</h5>
          <button
            className="notranslate btn btn-dark mb-4 mb-md-0"
            onClick={createOption}
          >
            <IconWithLoading
              icon={faLayerPlus}
              className="me-2"
              isLoading={isLoading}
            />
            {t('deposit.generate-address')} {activeTicker && cryptoTicketMap[activeTicker]}
          </button>
        </div>
      )}
      {!isInit && (
        <div className="ht-150 d-flex justify-content-center align-items-center">
          <Preloader inline />
        </div>
      )}
      {!currentOption && isError && (
        <div className="ht-150 d-flex flex-column justify-content-center align-items-center">
          <p className="tx-bold">{t('error.loading')}</p>
          <button
            className="btn btn-dark mb-4 mb-md-0"
            onClick={fetchOptions}
          >
            <IconWithLoading
              icon={faArrowsRotate}
              className="me-2"
              isLoading={isLoading}
            />
            {t('common.refresh')}
          </button>
        </div>
      )}
    </div>
  )
});

export default Deposit;
