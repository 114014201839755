import axios from "axios";
import {ENV} from "../constants";
import {applyAuthTokenInterceptor} from "axios-jwt";

export const axiosInstance = axios.create({ baseURL: `${ENV.API_URL}/api/v1` });

applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh: () => {
    return new Promise((resolve) => {
      resolve(localStorage.getItem('token') || '');
    });
  },  // async function that takes a refreshToken and returns a promise the resolves in a fresh accessToken
  header: "x-authorization",  // header name
  headerPrefix: "Bearer ",  // header value prefix
});

interface ApiProps {
  method: 'post' | 'get' | 'delete';
  path: string;
  data?: any;
}

export function api<T>({ method, path, data }: ApiProps): Promise<T> {
  const url = method === 'get' && data ? `${path}?${new URLSearchParams(data)}` : path;
  return new Promise((resolve, reject) => {
    axiosInstance<T>({
      method,
      url,
      data,
    }).then((response) => {
      if (response.data) {
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch((response) => {
      reject(response);
    });
  });
}
