import {observer} from "mobx-react-lite";
import TransactionsTable from "@components/common/transactions/TransactionsTable";
import {useTranslation} from "react-i18next";

const History = observer(() => {
  const {t} = useTranslation();

  return (
    <div id="history" className="container">
      <TransactionsTable title={<span>{t('history.title')}</span>} />
    </div>
  );
});

export default History;
