import React, {useMemo} from 'react';
import {tableHeaders} from "./TransactionsTable";
import FormattedNumber from "@components/common/FormattedNumber";
import FormattedDate from "@components/common/FormattedDate";
import {Currency, ITransaction, ITransactionStatus, ITransactionType} from "../../../constants";
import TransactionDetails from "@components/common/transactions/components/TransactionDetails";
import {useTranslation} from "react-i18next";
import CopyButton from "@components/common/CopyButton";
import {mapCurrency} from "@helpers/mapCurrency";

export interface TransactionsTableRowProps {
  entry: ITransaction;
  updateTable: () => void;
}

export default function TransactionsTableRow(props: TransactionsTableRowProps) {
  const {t} = useTranslation();
  const {entry} = props;

  const status = useMemo(() => {
    let result = entry.status;
    if (entry.type === ITransactionType.WITHDRAWAL && entry.status === ITransactionStatus.COMPLETE) {
      result = !entry.metadata.withdrawalConfirmed ? ITransactionStatus.IN_PROGRESS : ITransactionStatus.COMPLETE;
    }
    return t('transactions.status.' + result);
  }, [entry, t]);

  const metaInfo = () => {
    let result = null;

    // if (entry.type === TransactionType.LOCK_BALANCE && entry.metadata?.lockReason) {
    //   result = <>({t('transaction.lockReason.' + entry.metadata.lockReason)})</>;
    // }
    //
    // if (entry.type === TransactionType.UNLOCK_BALANCE && entry.metadata?.unlockReason) {
    //   result = <>({t('transaction.unlockReason.' + entry.metadata.unlockReason)})</>;
    // }

    return <div className="tx-gray-500">{result}</div>;
  };

  return (
    <tr>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[0])}
        </div>
        <FormattedDate date={entry.creationTimestamp}/>
        {/*<TransactionButton commonProps={props} type={TransactionButtonType.WITHDRAWAL_CANCEL}/>*/}
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[1])}
        </div>
        <span className="text-overflow-sm wd-150">
          {t('transactions.type.' + entry.type)}
          {metaInfo()}
        </span>
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[2])}
        </div>
        {entry.operations.map((operation) => (
          <div
            key={`transaction-${entry.id}-operation-${operation.id}`}
            className="tx-bold"
          >
            <FormattedNumber
              value={operation.balanceDiff}
              floor
              withSign
              postfix={mapCurrency(operation.currency as Currency)}
              className="d-block"
            />
            {operation.currency !== Currency.USD && (
              <FormattedNumber
                value={operation.balanceDiffUsd}
                suffix="≈$"
                floor
                className="d-block tx-normal tx-14"
              />
            )}
          </div>
        ))}
      </td>
      {/*}
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[3])}
        </div>

        {t('wallet.title.' + entry.walletCurrency)}
      </td>
      {*/}
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[3])}
        </div>
        <CopyButton text={entry.id} element={<span>{entry.id.split('-')[0]}</span>}/>
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[4])}
        </div>
        <div className="d-flex flex-row justify-content-center justify-content-lg-between d-lg-inline">
          <span>{status}</span>
          {/*<TransactionButton commonProps={props} type={TransactionButtonType.WITHDRAWAL_STATUS_INFO}/>*/}
          {/*<TransactionButton commonProps={props} type={TransactionButtonType.DETAILS}/>*/}
        </div>
      </td>
      <td>
        <div className="list-view-heading">
          {t(tableHeaders[5])}
        </div>

        <TransactionDetails entry={entry}/>
      </td>
    </tr>
  );
}
