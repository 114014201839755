import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faRightToBracket, faTriangleExclamation} from "@fortawesome/pro-duotone-svg-icons";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";

import Preloader from "@components/common/Preloader";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";

import ModalAlert from "@components/ModalAlert";
import FormattedNumber from "@components/common/FormattedNumber";
import FormattedDate from "@components/common/FormattedDate";
import IconWithLoading from "@components/common/IconWithLoading";

import {Currency, IPromoTurkeyData, PromoStatus} from "../../../../constants";

const PromoTurkeyMay2023 = observer(() => {
  const {t} = useTranslation();

  const {accountStore} = useStores();
  const handleResponse = useResponseHandler();

  const [isLoading, setIsLoading] = useState(false);
  const [promoData, setPromoData] = useState<IPromoTurkeyData>();
  const [isOpenInfoModal, setOpenInfoModal] = useState(false);

  const actionSentRef = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    accountStore.getPromoTurkey()
      .then((response) => {
        if (response.success && response.data) {
          setPromoData(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [accountStore, handleResponse]);

  const applyForPromo = useCallback(() => {
    if (actionSentRef.current) {
      return;
    }

    actionSentRef.current = true;
    setIsLoading(true);

    accountStore.applyForPromoTurkey()
      .then((response) => {
        if (response.success && response.data) {
          setPromoData(response.data);
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => {
        actionSentRef.current = false;
        setIsLoading(false);
      });
  }, [accountStore, handleResponse]);

  const openInfoModal = useCallback(() => {
    setOpenInfoModal(true);
  }, []);

  const promoEndTime = useMemo(() => {
    if (!promoData) {
      return new Date();
    }
    const startDate = new Date(promoData.promoStartFrom);
    startDate.setMonth(startDate.getMonth() + 1);
    return startDate;
  }, [promoData]);

  const teamTurnover = useMemo(() => {
    if (!promoData) {
      return 0;
    }
    const firstLineTurnover = promoData.firstLineUserTurnovers.reduce(
      (accumulator, currentValue) => accumulator + currentValue.personalTurnoverInUsd,
      0
    );
    return promoData.personalTurnoverInUsd + firstLineTurnover;
  }, [promoData]);

  const turnoverPercentage = useMemo(() => {
    if (!promoData) {
      return 0;
    }
    const userTurnover = teamTurnover / promoData.expectedTotalTurnover * 100;
    return userTurnover > 100 ? 100 : userTurnover;
  }, [promoData, teamTurnover]);

  return (
    <div className="col-12 col-lg-6 mb-4">
      <div className="card p-0 bg-dark">
        <div className="card-heading d-flex justify-content-between align-items-center mb-3 mt-3 mx-4 ms-5">
          <div className="notranslate d-flex align-items-center tx-24">
            {t('actions.promo-turkey-may-2023.heading')}
          </div>
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="tx-primary me-2 interactive"
            size="2x"
            onClick={openInfoModal}
            title={`${t('common.information')}`}
          />
        </div>

        <div className="card ht-100p gradient-primary">
          <div className="card-body">
            {isLoading && !promoData && (
              <div className="ht-150 d-flex justify-content-center align-items-center">
                <Preloader inline />
              </div>
            )}
            {promoData && (
              <div>
                <div className="text-center tx-bold tx-italic mb-4">
                  {t('actions.promo-turkey-may-2023.intro')}
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                  <div>
                    <div className="mb-3">
                      <div className="tx-uppercase tx-bold tx-spacing-1">
                        {t('actions.promo-turkey-may-2023.dates')}
                      </div>
                      <div>
                        <FormattedDate
                          date={promoData.promoStartFrom}
                          dateFormat="dd.MM"
                        />
                        {' - '}
                        <FormattedDate
                          date={promoEndTime}
                          dateFormat="dd.MM"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="tx-uppercase tx-bold tx-spacing-1">
                        {t('actions.promo-turkey-may-2023.turnover')}
                      </div>
                      <FormattedNumber
                        value={teamTurnover}
                        postfix={Currency.USDT}
                        className="d-inline mb-2"
                      />
                      {' '}{t('actions.promo-turkey-may-2023.from')}{' '}
                      <FormattedNumber
                        value={promoData.expectedTotalTurnover}
                        postfix={Currency.USDT}
                        className="d-inline mb-2"
                      />
                      {promoData.promoStatus !== PromoStatus.ACTIVE && (
                        <div className="mt-3">
                          {promoData.promoStatus === PromoStatus.FINISHED && t('actions.promo-turkey-may-2023.promo-finished')}
                          {promoData.promoStatus === PromoStatus.APPLIED && t('actions.promo-turkey-may-2023.promo-applied')}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-3 mt-md-0 wd-100p wd-md-100 tx-left tx-md-center ms-0 ms-md-3">
                    <div className="progress d-flex d-md-none">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={teamTurnover}
                        aria-valuemin={0}
                        aria-valuemax={promoData.expectedTotalTurnover}
                        style={{width: `${turnoverPercentage}%`}}
                      />
                    </div>
                    <div className="progress progress-vertical d-none d-md-flex mx-auto">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={promoData.promoStatus === PromoStatus.APPLIED ? promoData.expectedTotalTurnover : teamTurnover}
                        aria-valuemin={0}
                        aria-valuemax={promoData.expectedTotalTurnover}
                        style={{height: `${promoData.promoStatus === PromoStatus.APPLIED ? 100 : turnoverPercentage}%`}}
                      />
                    </div>
                    <div className="tx-uppercase tx-bold tx-spacing-1 tx-12 mt-1">{t('actions.promo-turkey-may-2023.turnover')}</div>
                  </div>
                </div>
                {promoData.promoStatus === PromoStatus.ACTIVE && teamTurnover >= promoData.expectedTotalTurnover && (
                  <div className="d-flex mt-5">
                    <button
                      className="notranslate btn btn-dark mb-4 mb-md-0 w-100"
                      onClick={applyForPromo}
                    >
                      <IconWithLoading
                        icon={faRightToBracket}
                        className="me-2"
                        isLoading={isLoading}
                      />
                      {t('actions.promo-turkey-may-2023.apply-for-promo')}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalAlert
        key="promo-turkey-2023-info"
        proceed={setOpenInfoModal}
        show={isOpenInfoModal}
        title={`${t('actions.promo-turkey-may-2023.modal.title')}`}
        cancelLabel={`${t('common.close')}`}
        size="lg"
        noOk
        enableEscape
        content={(
          <>
            <p>{t('actions.promo-turkey-may-2023.modal.text.1')}</p>
            <p>{t('actions.promo-turkey-may-2023.modal.text.2')}</p>
            <p className="text-center">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="tx-primary me-3"
                size="2x"
              />
              {t('actions.promo-turkey-may-2023.modal.text.3-note')}
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="tx-primary ms-3"
                size="2x"
              />
            </p>
            <p className="py-5">{t('actions.promo-turkey-may-2023.modal.text.4-dates')}</p>
            <p>{t('actions.promo-turkey-may-2023.modal.text.5')}</p>
            <p>
              <ul>
                <li>{t('actions.promo-turkey-may-2023.modal.text.6-list')}</li>
                <li>{t('actions.promo-turkey-may-2023.modal.text.7-list')}</li>
              </ul>
            </p>
            <p className="text-center">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="tx-primary me-3"
                size="2x"
              />
              {t('actions.promo-turkey-may-2023.modal.text.8-note')}
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="tx-primary ms-3"
                size="2x"
              />
            </p>
          </>
        )}
      />
    </div>
  )
});

export default PromoTurkeyMay2023;
