import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {useCallback, useEffect, useMemo, useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import Preloader from "@components/common/Preloader";
import {Currency, IWallet} from "../../../constants";
import {convertStringToNumber} from "@helpers/bignumber";
import {mapCurrency} from "@helpers/mapCurrency";
import FormattedNumber from "@components/common/FormattedNumber";
import IconWithLoading from "@components/common/IconWithLoading";
import {faMoneyFromBracket} from "@fortawesome/pro-duotone-svg-icons/faMoneyFromBracket";
import {useTranslation} from "react-i18next";
import cardWithdrawal from "@assets/images/card-withdrawal.png";

const Withdrawal = observer(() => {
  const {t} = useTranslation();
  const {accountStore} = useStores();
  const handleResponse = useResponseHandler();
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [value, setValue] = useState<number>();
  const [address, setAddress] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeWallet, setActiveWallet] = useState<IWallet>();

  const isAddressValid = useMemo((): boolean => {
    return RegExp(/^T[A-Za-z1-9]{33}$/).test(address);
  }, [address]);

  const fetchWallet = useCallback(() => {
    accountStore.getWallets()
      .then((response) => {
        const priority = [Currency.AFT, Currency.GAT, Currency.USDT];
        const list = (response || []).sort((a, b) => priority.indexOf(mapCurrency(a.currency)) - priority.indexOf(mapCurrency(b.currency)));
        setWallets(list);
        setActiveWallet((prevState) => prevState ? list.find((wallet) => mapCurrency(wallet.currency) === mapCurrency(prevState.currency)) : list[0]);
        setIsLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setIsLoading(false);
      });
  }, [accountStore, handleResponse]);

  const handleWithdraw = useCallback(() => {
    if (!activeWallet) return;

    if (!value || value < 0) {
      handleResponse(t('error.wrong-amount') + '', true);
      return;
    }

    if (value && value > activeWallet.balance) {
      handleResponse(t('error.not-enough-money') + '', true);
      return;
    }

    if (!isAddressValid) {
      handleResponse(t('error.wrong-usdt-address') + '', true);
      return;
    }

    setIsLoading(true);

    accountStore.withdraw(convertStringToNumber(value), activeWallet.currency, address)
      .then((response) => {
        if (response.success) {
          setIsLoading(true);
          fetchWallet();
          handleResponse(t('withdrawal.success') + '');
          setAddress('');
          setValue(0);
        } else {
          setIsLoading(false);
          handleResponse(response, true);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data, true);
        setIsLoading(false);
      });
  }, [isAddressValid, address, activeWallet, accountStore, handleResponse, fetchWallet, value, t]);

  useEffect(() => {
    fetchWallet();
  }, [fetchWallet]);

  return (
    <div id="withdrawal" className="container">
      <h1 className="mb-4">{t('withdrawal.title')}</h1>

      <div className="row">
        <div className="col-12 col-lg-9">
          <div className="card p-0 bg-dark ht-100p">
            {wallets.length > 0 && (
              <div className="d-flex justify-content-between my-3 ms-5">
                <ul className="nav justify-content-center justify-content-md-start">
                  {wallets.map((wallet) => (
                    <li key={`invest-wallet-tab-${wallet.currency}`} className="nav-item">
                      <button
                        className={`notranslate nav-link lh-1 tx-24 btn btn-link p-0 me-3 ${activeWallet?.currency === wallet.currency ? 'active tx-bold text-decoration-none' : 'tx-gray-600'}`}
                        onClick={() => {
                          setActiveWallet(wallet);
                          setValue(undefined);
                        }}
                      >
                        {mapCurrency(wallet.currency)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="card tx-center tx-md-left ht-100p gradient-primary">
              <div className="card-body col-lg-9 z-index-10">
                {activeWallet && (
                  <div>
                    <div className="notranslate ms-3 tx-18 mb-1">{mapCurrency(activeWallet.currency)}</div>
                    <div className="form-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0.00"
                        value={value}
                        min={0}
                        id="amount"
                        onChange={(event) => setValue(event.target.valueAsNumber)}
                        disabled={isLoading}
                      />
                    </div>
                    <div className="ms-3 tx-18 mb-1">{t('withdrawal.wallet-label')}</div>
                    <div className="form-group">
                      <input
                        type="text"
                        className={`form-control ${isAddressValid ? 'is-valid' : address && 'is-invalid'}`}
                        placeholder={t('withdrawal.address') + ''}
                        value={address}
                        min={0}
                        id="trc20-address"
                        onChange={(event) => setAddress(event.target.value)}
                        disabled={isLoading}
                      />
                    </div>

                    <div className="d-flex flex-column flex-md-row justify-content-md-start align-items-md-center mt-md-3 mb-0">
                      <button className="btn btn-black tx-primary mt-2 mt-lg-0 order-2 order-md-1" onClick={handleWithdraw}>
                        <IconWithLoading
                          icon={faMoneyFromBracket}
                          isLoading={isLoading}
                        />
                        {t('withdrawal.withdraw')}
                      </button>
                      <div className="ms-md-3 order-1 order-md-2 mt-3 mt-md-0">
                        {t('common.available')}:
                        <FormattedNumber
                          value={activeWallet.balance}
                          postfix={mapCurrency(activeWallet.currency)}
                          className="text-decoration-underline ms-1 cur-pointer"
                          onClick={() => setValue(activeWallet.balance)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!wallets.length && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
              </div>
              <img src={cardWithdrawal} alt="card-withdrawal" className="card-icon d-none d-md-block" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
});

export default Withdrawal;
