import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

import {faTelegramPlane, faInstagram, faFacebook, faTwitter, faTiktok, faVk} from "@fortawesome/free-brands-svg-icons";
import {useTranslation} from "react-i18next";

export const SocialLinks = () => {
  const {t} = useTranslation();

  const socials = [
    {
      icon: faInstagram,
      id: 'instagram',
      link: 'https://instagram.com/aurous.finance',
      title: 'Instagram',
    },
    {
      icon: faFacebook,
      id: 'facebook',
      link: 'https://www.facebook.com/groups/aurous.finance',
      title: 'Facebook',
    },
    {
      icon: faTwitter,
      id: 'twitter',
      link: 'https://twitter.com/aurous_finance',
      title: 'Twitter',
    },
    // {
    //   icon: faLinkedin,
    //   id: 'linkedin',
    //   link: 'https://www.linkedin.cn/',
    //   title: 'LinkedIn',
    // },
    // {
    //   icon: faReddit,
    //   id: 'reddit',
    //   link: 'https://www.reddit.com/',
    //   title: 'Reddit',
    // },
    {
      icon: faTelegramPlane,
      id: 'telegram',
      link: t('links.telegram'),
      title: 'Telegram',
    },
    {
      icon: faTiktok,
      id: 'tiktok',
      link: 'https://www.tiktok.com/@aurous.finance',
      title: 'TikTok',
    },
    // {
    //   icon: faMedium,
    //   id: 'medium-com',
    //   link: 'https://medium.com/',
    //   title: 'Medium',
    // },
    {
      icon: faVk,
      id: 'vk',
      link: 'https://vk.com/aurous.finance',
      title: 'VK',
    },
  ];

  return (
    <div className="d-flex flex-wrap mt-3 justify-content-center justify-content-md-start">
      {socials.map((social) => (
        <a
          className={'footer-link tx-20 me-2 tx-' + social.id}
          href={social.link}
          target="_blank"
          rel="noreferrer"
          title={social.title}
          key={social.title}
        >
          <FontAwesomeIcon icon={social.icon as IconDefinition} />
        </a>
      ))}
    </div>
  )
}
