import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {Element, scroller} from "react-scroll/modules";
import TransactionsTableRow from "./TransactionsTableRow";
import useResponseHandler from "@hooks/useResponseHandler";
import {useStores} from "@hooks/useStores";
import {faTable} from "@fortawesome/pro-regular-svg-icons/faTable";
import {faThList} from "@fortawesome/pro-regular-svg-icons/faThList";
import Preloader from "@components/common/Preloader";
import Placeholder from "@components/common/Placeholder";
import {faArrowLeft} from "@fortawesome/pro-regular-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import {ITransaction, ITransactionType} from "../../../constants";
import {useTranslation} from "react-i18next";

export interface TransactionsTableProps {
  types?: ITransactionType[];
  id?: string;
  className?: string;
  scrollContainer?: string;
  title?: JSX.Element;
  noTitle?: boolean;
}

export interface TransactionsTableRef {
  refresh: () => void;
}

export const tableHeaders = [
  'common.date',
  'common.type',
  'common.qty',
  // 'common.wallet',
  'common.id',
  'common.status',
  'common.details',
];

const TransactionsTable = forwardRef<TransactionsTableRef, TransactionsTableProps>(
  (props: TransactionsTableProps, ref) => {
    const {t} = useTranslation();
    const {
      id = 'transactions-table',
      className,
      title = t('transactions.title') + '',
      noTitle = false,
    } = props;
    const {accountStore} = useStores();
    const handleResponse = useResponseHandler();
    const [transactions, setTransactions] = useState<ITransaction[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [/*isInitialLoading*/, setInitialLoading] = useState<boolean>(true);
    const [transactionsLoaded, setTransactionsLoaded] = useState<boolean>(false);
    const [listView, setListView] = useState<boolean>(false);

    const loadTransactions = useCallback((nextPage: number) => {
      setLoading(true);
      accountStore.getTransactions(nextPage)
        .then((response) => {
          // if (scrollContainer && !isInitialLoading && transactionsLoaded && transactions.length && response.data?.content.length) {
          //   scroller.scrollTo(id, {
          //     duration: 500,
          //     smooth: true,
          //     offset: -75,
          //     containerId: scrollContainer,
          //   });
          // }

          setTransactions(response.content);
          setTotal(response.totalElements);
          setLoading(false);
          setTransactionsLoaded(true);
          setInitialLoading(false);
        })
        .catch((response) => {
          handleResponse(response.response.data);
          setLoading(false);
          setTransactionsLoaded(true);
          setInitialLoading(false);
        });
    }, [accountStore, handleResponse]);

    useImperativeHandle(
      ref,
      () => ({
        refresh() {
          loadTransactions(page);
        }
      }),
    );

    useEffect(() => {
      if (!isLoading && !transactionsLoaded && !transactions.length) {
        loadTransactions(0);
      }
    }, [isLoading, transactionsLoaded, transactions.length, loadTransactions]);

    const handlePageChange = useCallback((newPage: number) => {
      setPage(newPage);
      loadTransactions(newPage);
    }, [loadTransactions]);

    return (
      <div className={className}>
        <div className="d-md-flex flex-md-row align-items-start justify-content-start mb-2 mb-md-4">
          <h2 className="mb-3 mb-md-0 mr-auto">
            <div
              className="table-switch d-none d-lg-inline-flex me-3"
              onClick={() => setListView(!listView)}
            >
              <span className={!listView ? 'is-active' : ''}>
                <FontAwesomeIcon icon={faTable}/>
              </span>
              <span className={listView ? 'is-active' : ''}>
                <FontAwesomeIcon icon={faThList}/>
              </span>
            </div>

            <span>{!noTitle && title}</span>
          </h2>

          {/*<TrackTransaction updateTable={() => loadTransactions(page)}/>*/}

          {/*<Link*/}
          {/*  className="btn btn-sm btn-info mb-3 mb-md-0"*/}
          {/*  to="/api/v1/transactions/export"*/}
          {/*  target="_blank"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={faFileDownload} className="mr-2"/>*/}
          {/*  {t('transaction.export')}*/}
          {/*</Link>*/}
        </div>

        <div id={id}>
          <div className={'table-wrapper-responsive' + (listView ? ' list-view' : '')}>
            {isLoading && transactions.length > 0 && <Preloader/>}
            <table className="table mb-0 tx-center">
              <thead className="bg-dark tx-white">
                <tr>
                  {tableHeaders.map((header, index) => {
                    const classList = ['bd-t-0-f'];

                    if (index === 0) {
                      classList.push('wd-lg-200');
                    }

                    if (index === 1) {
                      classList.push('wd-lg-250');
                    }

                    return (
                      <th className={classList.join(' ')} key={'transactions-th-' + index}>{t(header)}</th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {!transactionsLoaded && (<>
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <td key={'transactions-placeholder-1-' + index}><Placeholder width={190}/></td>
                    ))}
                  </tr>
                  <tr>
                    {tableHeaders.map((header, index) => (
                      <td key={'transactions-placeholder-2-' + index}><Placeholder width={190}/></td>
                    ))}
                  </tr>
                </>)}
                {transactions.length > 0 && transactions.map((entry, index) => (
                  <TransactionsTableRow
                    entry={entry}
                    updateTable={() => loadTransactions(page)}
                    key={'table-transaction-' + index + '-' + entry.creationTimestamp}
                  />
                ))}
                {transactionsLoaded && transactions.length === 0 && (
                  <tr>
                    <td colSpan={tableHeaders.length}>
                      {t('transactions.no-transactions')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {total > 10 && (
          <ReactPaginate
            previousLabel={<FontAwesomeIcon icon={faArrowLeft}/>}
            nextLabel={<FontAwesomeIcon icon={faArrowRight}/>}
            pageCount={Math.ceil(total / 10)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            containerClassName={'pagination mt-3 pb-3 justify-content-center'}
            activeClassName={'active'}
            onPageChange={(data) => handlePageChange(data.selected)}
            eventListener={isLoading ? '' : 'onClick'}
          />
        )}
      </div>
    );
  }
);

export default TransactionsTable;
