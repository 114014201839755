import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import React, {useCallback, useEffect, useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import {ENV, IProfile, nameSurnameRx, refIdRx, usernameRx} from "../../../constants";
import {useTranslation} from "react-i18next";
import Preloader from "@components/common/Preloader";
import CopyButton from "@components/common/CopyButton";
import IconWithLoading from "@components/common/IconWithLoading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/pro-duotone-svg-icons/faArrowUpRightFromSquare";
import {faWindowClose} from "@fortawesome/pro-regular-svg-icons/faWindowClose";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faEyeSlash} from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import {faKey} from "@fortawesome/pro-regular-svg-icons/faKey";
import {faPencil} from "@fortawesome/pro-regular-svg-icons/faPencil";
import {faTimes} from "@fortawesome/pro-regular-svg-icons/faTimes";
import {faSave} from "@fortawesome/pro-regular-svg-icons/faSave";
import confirmAlert from "@components/ConfirmAlert";

const Profile = observer(() => {
  const {t} = useTranslation();
  const {accountStore} = useStores();

  const [account, setAccount] = useState<IProfile>();
  const [tgToken, setTgToken] = useState('');
  const [isTokenLoading, setIsTokenLoading] = useState(false);
  const [isProcessingPassword, setIsProcessingPassword] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [username, setUsername] = useState(account?.username || '');
  const [name, setName] = useState(account?.name || '');
  const [surname, setSurname] = useState(account?.surname || '');
  const [userRefId, setUserRefId] = useState(account?.ownRefId || '');

  const handleResponse = useResponseHandler();

  useEffect(() => {
    accountStore.getProfileInfo()
      .then((response) => {
        setAccount(response);
        setUsername(response.username);
        setName(response.name);
        setSurname(response.surname);
        setUserRefId(response.ownRefId);
      })
      .catch((response) => {
        handleResponse(response.response.data);
      });
  }, [accountStore, handleResponse]);

  useEffect(() => {
    if (account && !account.telegramEnabled) {
      setIsTokenLoading(true);
      accountStore.getTgToken().then((response) => {
        if (response.success) {
          setTgToken(response?.data?.token || '');
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => setIsTokenLoading(false));
    }
  }, [account, accountStore, handleResponse]);

  const generateTgToken = useCallback(() => {
    setIsTokenLoading(true);
    accountStore.generateTgToken()
      .then((response) => {
        if (response.success) {
          setTgToken(response?.data?.token || '');
        } else {
          handleResponse(response);
        }
      })
      .catch((response) => {
        handleResponse(response.response.data);
      })
      .finally(() => setIsTokenLoading(false));
  }, [accountStore, handleResponse]);

  const isValidProfileInput = useCallback(() => {
    if (name) {
      if (name.length < 2 || name.length > 50)
      {
        handleResponse(t('error.name-length') + '', true);
        return false;
      }

      if (!(nameSurnameRx.test(name))) {
        handleResponse(t('error.wrong-name') + '', true);
        return false;
      }
    }

    if (surname) {
      if (surname.length < 2 || surname.length > 50)
      {
        handleResponse(t('error.surname-length') + '', true);
        return false;
      }

      if (!(nameSurnameRx.test(surname))) {
        handleResponse(t('error.wrong-surname') + '', true);
        return false;
      }
    }

    if (username.length < 4 || username.length > 12) {
      handleResponse(t('error.username-length') + '', true);
      return false;
    }

    if (!(usernameRx.test(username))) {
      handleResponse(t('error.wrong-username') + '', true);
      return false;
    }

    if (userRefId.length < 6 || userRefId.length > 10) {
      handleResponse(t('error.referral-id-length') + '', true);
      return false;
    }

    if (!(refIdRx.test(userRefId))) {
      handleResponse(t('error.wrong-referral-id') + '', true);
      return false;
    }

    return true;
  }, [userRefId, username, name, surname, handleResponse, t]);

  const handleProfileUpdate = useCallback(() => {
    if (!isValidProfileInput()) {
      return;
    }

    setLoading(true);
    accountStore.updateProfileInfo({username, name, surname, ownRefId: userRefId})
      .then((response) => {
        if (response.success) {
          handleResponse(t('profile.update-success') + '', false);
          if (response.data) {
            setAccount(response.data);
            setUsername(response.data.username);
            setName(response.data.name);
            setSurname(response.data.surname);
            setUserRefId(response.data.ownRefId);
          }
          setIsEditMode(false);
        } else {
          handleResponse(response);
        }
        setLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setLoading(false);
      });
  }, [accountStore, username, name, surname, userRefId, isValidProfileInput, handleResponse, t]);

  const isValidPasswordsInput = useCallback(() => {
    if (confirmPassword !== newPassword) {
      handleResponse(t('error.passwords-not-match') + '', true);
      return;
    }

    if (password.length < 8 || password.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    if (newPassword.length < 8 || newPassword.length > 40) {
      handleResponse(t('error.password-length') + '', true);
      return;
    }

    return true;
  }, [password, newPassword, confirmPassword, handleResponse, t]);

  const handlePasswordChange = useCallback(() => {
    if (!isValidPasswordsInput()) {
      return;
    }

    setIsProcessingPassword(true);
    accountStore.changePassword(password, newPassword)
      .then((response) => {
        if (response.success) {
          handleResponse(t('profile.password-changed') + '', false);
          setPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setIsEditMode(false);
        } else {
          handleResponse(response);
        }
        setIsProcessingPassword(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setIsProcessingPassword(false);
      });
  }, [newPassword, password, accountStore, handleResponse, isValidPasswordsInput, t]);

  const confirmTelegramDisconnect = useCallback(() => {
    confirmAlert({
      title: t('profile.disconnect-modal.title') + '',
      okLabel: t('profile.disconnect-modal.confirm') + '',
      okVariant: 'danger',
      size: 'lg',
      enableEscape: true,
      content: (
        <p>{t('profile.disconnect-modal.confirmation')}</p>
      ),
    }).then((resp) => {
      if (!!resp) {
        setIsTokenLoading(true);

        accountStore.disconnectFromTelegram()
          .then((response) => {
            if (response.success) {
              handleResponse(t('profile.disconnect-modal.success') + '', false);
              if (response.data) {
                setAccount(response.data);
                setTgToken('');
              }
            } else {
              handleResponse(response);
            }
          })
          .catch((response) => {
            handleResponse(response.response.data);
          })
          .finally(() => setIsTokenLoading(false));
      }
    });
  }, [t, accountStore, handleResponse]);

  return (
    <div id="profile" className="container">
      <div className="card p-0 bg-dark">
        <div className={`${isEditMode ? 'd-md-flex mb-1 mb-md-3' : 'd-flex mb-3'} justify-content-between align-items-center px-4 px-md-5 mt-3`}>
          <h2 className="mb-0 tx-24">{t('profile.title')}</h2>

          <div>
            {!isEditMode && (
              <button className="btn btn-link tx-primary px-0" onClick={() => setIsEditMode(true)}>
                <FontAwesomeIcon icon={faPencil} className="me-2" />
                {t('common.edit')}
              </button>
            )}
            {isEditMode && (
              <div className="d-flex d-md-block justify-content-between">
                <button className="btn btn-link tx-danger px-0 me-4" onClick={() => setIsEditMode(false)} disabled={isLoading}>
                  <FontAwesomeIcon icon={faTimes} className="me-2" />
                  {t('common.cancel')}
                </button>
                <button className="btn btn-link tx-success px-0" onClick={handleProfileUpdate} disabled={isLoading}>
                  <IconWithLoading icon={faSave} className="me-2" isLoading={isLoading} />
                  {t('common.save')}
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="card ht-100p gradient-primary p-4 p-md-5">
          <div className="card-body">
            {!account && (
              <Preloader inline />
            )}
            {account && (
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1">{t('common.user-ref-id')}</div>
                    {!isEditMode && (<div className="notranslate tx-bold">{account.ownRefId}</div>)}
                    {isEditMode && (
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('common.id') + ''}
                          value={userRefId}
                          id="ownRefId"
                          onChange={(event) => setUserRefId(event.target.value)}
                          disabled={isLoading}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1">{t('common.referral')}</div>
                    <div className="notranslate tx-bold">{account.uplinerReferralId}</div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1">Email</div>
                    <div className="notranslate tx-bold">{account.email}</div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1">{t('common.username')}</div>
                    {!isEditMode && (<div className="notranslate tx-bold">{account.username}</div>)}
                    {isEditMode && (
                      <div className="form-group mb-0">
                        <input
                          type="email"
                          className="form-control form-control-sm"
                          placeholder={t('common.username') + ''}
                          value={username}
                          id="username"
                          onChange={(event) => setUsername(event.target.value)}
                          disabled={isLoading}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1">{t('common.name')}</div>
                    {!isEditMode && (<div className="notranslate tx-bold">{account.name}</div>)}
                    {isEditMode && (
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('common.name') + ''}
                          value={name}
                          id="name"
                          onChange={(event) => setName(event.target.value)}
                          disabled={isLoading}
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1">{t('common.surname')}</div>
                    {!isEditMode && (<div className="notranslate tx-bold">{account.surname}</div>)}
                    {isEditMode && (
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('common.surname') + ''}
                          value={surname}
                          id="surname"
                          onChange={(event) => setSurname(event.target.value)}
                          disabled={isLoading}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1">Telegram bot</div>
                    <div className="tx-bold">
                      {t(account.telegramEnabled ? 'profile.tg-connected' : 'profile.tg-not-connected')}
                      {account.telegramEnabled && (
                        <>
                          {' '}
                          <FontAwesomeIcon icon={faWindowClose} role="button" onClick={confirmTelegramDisconnect} title={`${t('profile.tg-disconnect')}`}/>
                        </>
                      )}
                    </div>
                  </div>

                  {tgToken && (
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                      <div className="tx-uppercase tx-spacing-1 mb-1">{t('profile.tg-code')}</div>
                      <CopyButton text={tgToken} className="btn btn-sm btn-black tx-primary mb-2" iconClass="tx-primary me-2" />
                      <div>{t('profile.tg-description')}</div>
                    </div>
                  )}
                  <div className="col-12 col-md-6 col-lg-4 mb-3">
                    <div className="tx-uppercase tx-spacing-1 mb-1">Telegram</div>
                    {!account.telegramEnabled && !tgToken ? (
                      <button
                        className="btn btn-sm btn-black tx-primary"
                        onClick={generateTgToken}
                        disabled={isTokenLoading}
                      >
                        <IconWithLoading className="tx-primary me-2" isLoading={isTokenLoading} />
                        {t('profile.get-tg-code')}
                      </button>
                    ) : (
                      <a
                        className="btn btn-sm btn-black tx-primary"
                        href={ENV.TG_BOT_URL}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="tx-primary me-2" />
                        {t('profile.open-tg-bot')}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="card p-0 bg-dark mt-5">
        <h2 className="mb-3 mt-3 ms-4 ms-md-5 tx-24">{t('profile.change-password')}</h2>

        <div className="card ht-100p gradient-primary p-4 p-md-5">
          <div className="card-body">
            <div className="ms-3 tx-18 mb-1">{t('profile.current-password')}</div>
            <div className="form-group mb-2">
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder={t('profile.current-password') + ''}
                  value={password}
                  id="password"
                  onChange={(event) => setPassword(event.target.value)}
                  disabled={isProcessingPassword}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} role="button" onClick={() => setShowPassword((prevState) => !prevState)}/>
                </div>
              </div>
            </div>

            <div className="ms-3 tx-18 mb-1">{t('profile.new-password')}</div>
            <div className="form-group mb-2">
              <div className="input-group">
                <input
                  type={showNewPassword ? "text" : "password"}
                  className="form-control"
                  placeholder={t('profile.new-password') + ''}
                  value={newPassword}
                  id="new-password"
                  onChange={(event) => setNewPassword(event.target.value)}
                  disabled={isProcessingPassword}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} role="button" onClick={() => setShowNewPassword((prevState) => !prevState)}/>
                </div>
              </div>
            </div>

            <div className="ms-3 tx-18 mb-1">{t('profile.confirm-password')}</div>
            <div className="form-group mb-2">
              <div className="input-group">
                <input
                  type={showNewPassword ? "text" : "password"}
                  className="form-control"
                  placeholder={t('profile.confirm-password') + ''}
                  value={confirmPassword}
                  id="confirm-new-password"
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  disabled={isProcessingPassword}
                />
                <div className="input-group-text">
                  <FontAwesomeIcon icon={showNewPassword ? faEye : faEyeSlash} role="button" onClick={() => setShowNewPassword((prevState) => !prevState)}/>
                </div>
              </div>
            </div>

            <button className="btn btn-black tx-primary mt-2 mt-lg-0 order-2 order-md-1" onClick={handlePasswordChange} disabled={isProcessingPassword}>
              <IconWithLoading
                icon={faKey}
                isLoading={isProcessingPassword}
              />
              {t('profile.change-password')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
});

export default Profile;
