import {useState} from "react";
import DocViewer, {PDFRenderer} from "react-doc-viewer";


import ModalAlert from "@components/ModalAlert";
import {useTranslation} from "react-i18next";

interface PdfFileProps {
  name: string;
  fileUri: string;
  fileData?: string;
}

export const PdfFile = ({ name, fileUri, fileData }: PdfFileProps) => {
  const {t} = useTranslation();

  const [openPdfModal, setOpenPdfModal] = useState(false);

  return (
    <>
      <button className="btn btn-link tx-white p-0" onClick={() => setOpenPdfModal(true)}>{name}</button>

      <ModalAlert
        key="usd-info"
        proceed={setOpenPdfModal}
        show={openPdfModal}
        title={name}
        cancelLabel={`${t('common.close')}`}
        size="xl"
        noOk
        enableEscape
        content={(
          <DocViewer
            pluginRenderers={[PDFRenderer]}
            documents={[
              { uri: fileUri, fileData }
            ]}
          />
        )}
      />
    </>
  );
}
