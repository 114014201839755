import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {useEffect, useMemo, useState} from "react";
import useResponseHandler from "@hooks/useResponseHandler";
import Preloader from "@components/common/Preloader";
import {Currency, IRankInfo, IRankRule, IReferralInfo, IReferralRule, ITokenBank} from "../../../constants";
import FormattedNumber from "@components/common/FormattedNumber";
import CopyButton from "@components/common/CopyButton";
import {useTranslation} from "react-i18next";
import ReferralSubLines from "@pages/Referral/components/ReferralSubLines";

const Referral = observer(() => {
  const {t} = useTranslation();
  const {accountStore, globalStore} = useStores();
  const handleResponse = useResponseHandler();

  const [tokenBank, setTokenBank] = useState<ITokenBank>();
  const [referralInfo, setReferralInfo] = useState<IReferralInfo>();
  const [referralRules, setReferralRules] = useState<IReferralRule[]>([]);
  const [infoLoading, setInfoLoading] = useState(true);
  const [rulesLoading, setRulesLoading] = useState(true);
  const [activeRule, setActiveRule] = useState<IReferralRule>();

  const [rankInfo, setRankInfo] = useState<IRankInfo>();
  const [rankRules, setRankRules] = useState<IRankRule[]>([]);
  const [rankInfoLoading, setRankInfoLoading] = useState(true);
  const [rankRulesLoading, setRankRulesLoading] = useState(true);
  const [activeRankRule, setActiveRankRule] = useState<IRankRule>();

  useEffect(() => {
    globalStore.getTokenBank().then(setTokenBank);
  }, [globalStore]);

  const refLink = useMemo(() => {
    if (!referralInfo) {
      return '';
    }

    const origin = document.location.origin.replace("https://app.", "https://")
    return origin + '/?ref=' + referralInfo.refId;
  }, [referralInfo]);

  useEffect(() => {
    accountStore.getReferralRules()
      .then((response) => {
        setReferralRules(response);
        setRulesLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setRulesLoading(false);
      });
  }, [accountStore, handleResponse]);

  useEffect(() => {
    accountStore.getReferralInfo()
      .then((response) => {
        setReferralInfo(response);
        setInfoLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setInfoLoading(false);
      });
  }, [accountStore, handleResponse]);

  useEffect(() => {
    accountStore.getRankRules()
      .then((response) => {
        setRankRules(response.data || []);
        setRankRulesLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setRankRulesLoading(false);
      });
  }, [accountStore, handleResponse]);

  useEffect(() => {
    accountStore.getRankInfo()
      .then((response) => {
        setRankInfo(response.data || undefined);
        setRankInfoLoading(false);
      })
      .catch((response) => {
        handleResponse(response.response.data);
        setRankInfoLoading(false);
      });
  }, [accountStore, handleResponse]);

  useEffect(() => {
    if (!activeRankRule && rankRules.length && rankInfo) {
      setActiveRankRule(rankRules[rankInfo.rankLevel !== rankRules.length ? rankInfo.rankLevel : rankRules.length - 1]);
    }
  }, [activeRankRule, rankRules, rankInfo]);

  useEffect(() => {
    if (!activeRule && referralRules.length && referralInfo) {
      setActiveRule(referralRules[referralInfo.refLevel !== referralRules.length ? referralInfo.refLevel : referralRules.length - 1]);
    }
  }, [activeRule, referralRules, referralInfo]);

  const currentRank = useMemo(() => {
    if (rankRules.length && rankInfo && rankInfo.rankLevel > 0) {
      return rankRules.find(({ level }) => level === rankInfo.rankLevel) || null;
    }
    return null;
  }, [rankRules, rankInfo]);

  const totalAufEarnedInUsdt = useMemo(() => {
    if (tokenBank && referralInfo) {
      return referralInfo.totalAufEarned * tokenBank.aufPrice;
    }
    return 0;
  }, [tokenBank, referralInfo]);

  const totalAufMissedInUsdt = useMemo(() => {
    if (tokenBank && referralInfo) {
      return referralInfo.totalAufMissed * tokenBank.aufPrice;
    }
    return 0;
  }, [tokenBank, referralInfo]);

  return (
    <div id="referral" className="container">
      <h1 className="mb-4">{t('referral.rank-title')}</h1>
      <div className="row">
        <div className="col-12 mb-4">
          {t('referral.rank-description')}
        </div>
        <div className="col-12 col-lg-4 mb-4">
          <div className="card gradient-primary py-4 ht-100p">
            <div className="card-body">
              {rankInfoLoading && !rankInfo && (
                <div className="ht-150 d-flex justify-content-center align-items-center">
                  <Preloader inline />
                </div>
              )}
              {rankInfo && (
                <div className="row ht-100p">
                  <div className="col-6 mb-4">
                    <div className="tx-uppercase tx-spacing-1">{t('referral.rank.level')}</div>
                    <FormattedNumber
                      value={rankInfo.rankLevel}
                      className="tx-bold"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-uppercase tx-spacing-1">{t('referral.rank.rank')}</div>
                    <div className="tx-bold">{currentRank?.name}</div>
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.main-branch-turnover')}</div>
                    <div className="tx-bold">{rankInfo.mainBranchUsername}</div>
                    <FormattedNumber
                      value={rankInfo.mainBranchTurnover}
                      postfix={Currency.USDT}
                      className="tx-bold"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.secondary-branch-turnover')}</div>
                    <div className="tx-bold">{rankInfo.secondaryBranchUsername}</div>
                    <FormattedNumber
                      value={rankInfo.secondaryBranchTurnover}
                      postfix={Currency.USDT}
                      className="tx-bold"
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.other-branches-turnover')}</div>
                    <FormattedNumber
                      value={rankInfo.otherBranchesTurnover}
                      postfix={Currency.USDT}
                      className="tx-bold"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.personal-turnover')}</div>
                    <FormattedNumber
                      value={rankInfo.personalTurnover}
                      postfix={Currency.USDT}
                      className="tx-bold"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-8 mb-4 d-md-flex flex-column justify-content-between">
          <div className="card p-0 bg-dark ht-100p">
            {!rankRulesLoading && rankRules.length > 0 && (
              <ul className="nav justify-content-between my-1 px-5 nav-levels">
                <li className="nav-item d-flex align-items-center">
                  <span className="tx-normal">{t('referral.levels')}</span>
                </li>
                {rankRules.map((rankRule) => (
                  <li key={`ref-rule-tab-${rankRule.level}`} className="nav-item">
                    <button
                      type="button"
                      className={`nav-link btn btn-link p-0 ${activeRankRule?.level === rankRule.level ? 'active' : 'tx-gray-600'}`}
                      onClick={() => {
                        setActiveRankRule(rankRule);
                      }}
                    >
                      {rankRule.level}
                    </button>
                  </li>
                ))}
              </ul>
            )}

            <div className="card tx-center tx-md-left gradient-primary ht-100p">
              <div className="card-body">
                {activeRankRule && (
                  <div>
                    <div className="row align-items-center">
                      <div className="col-12 col-sm-4 mb-4">
                        <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.rank')}</div>
                        <div className="tx-bold">{activeRankRule.name}</div>
                      </div>
                      <div className="col-12 col-sm-4 mb-4">
                        <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.personal-turnover')}</div>
                        <FormattedNumber
                          value={activeRankRule.personalTurnover}
                          postfix="USDT"
                          className="tx-bold"
                        />
                      </div>
                      <div className="col-12 col-sm-4 mb-4">
                        <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.main-branch-turnover')}</div>
                        {activeRankRule.mainBranchTurnover ? (
                          <FormattedNumber
                            value={activeRankRule.mainBranchTurnover}
                            postfix={Currency.USDT}
                            className="tx-bold"
                          />
                          ) : <div className="tx-bold">-</div>
                        }
                      </div>
                      <div className="col-12 col-sm-4 mb-4">
                        <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.secondary-branch-turnover')}</div>
                        {activeRankRule.secondaryBranchTurnover ? (
                          <FormattedNumber
                            value={activeRankRule.secondaryBranchTurnover}
                            postfix={Currency.USDT}
                            className="tx-bold"
                          />
                          ) : <div className="tx-bold">-</div>
                        }
                      </div>
                      <div className="col-12 col-sm-4 mb-4">
                        <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.other-branches-turnover')}</div>
                        {activeRankRule.otherBranchesTurnover ? (
                          <FormattedNumber
                            value={activeRankRule.otherBranchesTurnover}
                            postfix={Currency.USDT}
                            className="tx-bold"
                          />
                          ) : <div className="tx-bold">-</div>
                        }
                      </div>
                      <div className="col-12 col-sm-4 mb-4">
                        <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.rank.bonus')}</div>
                        <FormattedNumber
                          value={activeRankRule.rankBonus}
                          postfix={Currency.USDT}
                          className="tx-bold"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!rankRules.length && (
                  <div className="ht-150 d-flex justify-content-center align-items-center">
                    <Preloader inline />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="mt-5 mb-4">{t('referral.title')}</h1>
      <div className="row">
        <div className="col-12 col-lg-6 mb-4">
          {t('referral.description')}
        </div>
        <div className="col-12 col-lg-6 mb-4">
          <h5 className="tx-18 tx-md-20 tx-normal mb-1">{t('referral.link')}</h5>
          {referralInfo && (
            <div>
              <CopyButton
                text={refLink}
                className="btn btn-link p-0 tx-primary tx-left"
                iconClass="tx-primary tx-14 me-2"
              />
              <div className="d-flex justify-content-start mt-1">
                <CopyButton
                  text={refLink}
                  element={t('common.copy-link')}
                  className="btn btn-dark tx-primary"
                  iconClass="tx-primary me-2"
                />
                <CopyButton
                  text={referralInfo.refId}
                  element={t('common.copy-code')}
                  className="btn btn-dark tx-primary ms-2"
                  iconClass="tx-primary me-2"
                />
              </div>
            </div>
          )}
        </div>
        <div className="col-12 col-lg-5 mb-4">
          <div className="card gradient-primary py-4 ht-100p">
            <div className="card-body">
              {infoLoading && !referralInfo && (
                <div className="ht-150 d-flex justify-content-center align-items-center">
                  <Preloader inline />
                </div>
              )}
              {referralInfo && (
                <div className="row ht-100p">
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-uppercase tx-spacing-1">{t('referral.level')}</div>
                    <FormattedNumber
                      value={referralInfo.refLevel}
                      className="tx-bold"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-uppercase tx-spacing-1">{t('referral.invited')}</div>
                    <FormattedNumber
                      value={referralInfo.totalReferrals}
                      className="tx-bold"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-uppercase tx-spacing-1">{t('referral.earned')}</div>
                    <FormattedNumber
                      value={referralInfo.totalAufEarned}
                      postfix={Currency.AFT}
                      className="tx-bold"
                    />
                    {totalAufEarnedInUsdt > 0 && (
                      <FormattedNumber
                        className="d-block tx-normal tx-14"
                        value={totalAufEarnedInUsdt}
                        suffix="≈$"
                        floor
                      />
                    )}
                  </div>
                  <div className="col-12 col-sm-6 mb-4">
                    <div className="tx-uppercase tx-spacing-1">{t('referral.missed')}</div>
                    <FormattedNumber
                      value={referralInfo.totalAufMissed}
                      postfix={Currency.AFT}
                      className="tx-bold"
                    />
                    {totalAufMissedInUsdt > 0 && (
                      <FormattedNumber
                        className="d-block tx-normal tx-14"
                        value={totalAufMissedInUsdt}
                        suffix="≈$"
                        floor
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/*{referralInfo?.refLevel !== 10 && (*/}
          <div className="col-12 col-lg-7 mb-4 d-md-flex flex-column justify-content-between">
            <div className="card p-0 bg-dark ht-100p">
              {!rulesLoading && referralRules.length > 0 && (
                <ul className="nav justify-content-between my-1 px-5 nav-levels">
                  <li className="nav-item d-flex align-items-center">
                    <span className="tx-normal">{t('referral.levels')}</span>
                  </li>
                  {referralRules.map((rule) => (
                    <li key={`ref-rule-tab-${rule.level}`} className="nav-item">
                      <button
                        type="button"
                        className={`nav-link btn btn-link p-0 ${activeRule?.level === rule.level ? 'active' : 'tx-gray-600'}`}
                        onClick={() => {
                          setActiveRule(rule);
                        }}
                      >
                        {rule.level}
                      </button>
                    </li>
                  ))}
                </ul>
              )}

              <div className="card tx-center tx-md-left gradient-primary ht-100p">
                <div className="card-body">
                  {activeRule && (
                    <div>
                      <div className="row align-items-center">
                        <div className="col-12 col-sm-4 mb-4">
                          <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.percents')}</div>
                          <FormattedNumber
                            value={activeRule.rewardPercents}
                            postfix="%"
                            className="tx-bold"
                          />
                        </div>
                        <div className="col-12 col-sm-4 mb-4">
                          <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.min-deposit')}</div>
                          <FormattedNumber
                            value={activeRule.minPersonalUsdDeposit}
                            postfix={Currency.USDT}
                            className="tx-bold"
                          />
                        </div>
                        <div className="col-12 col-sm-4 mb-4">
                          <div className="tx-12 tx-uppercase tx-medium tx-spacing-1">{t('referral.min-network-turnover')}</div>
                          <FormattedNumber
                            value={activeRule.minPersonalUsdTurnover}
                            postfix={Currency.USDT}
                            className="tx-bold"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!referralRules.length && (
                    <div className="ht-150 d-flex justify-content-center align-items-center">
                      <Preloader inline />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        {/*)}*/}
      </div>

      <div className="table-wrapper">
        <table className="table tx-center mb-0 valign-middle">
          <thead className="bg-dark tx-white">
            <tr className="valign-middle">
              <th className="bd-t-0-f">{t('referral.line')}</th>
              <th className="bd-t-0-f">{t('referral.partners')}</th>
              <th className="bd-t-0-f">{t('referral.earned')} /<br/>{t('referral.missed')}</th>
              <th className="bd-t-0-f">{Currency.AFT} {t('referral.turnover')}</th>
              <th className="bd-t-0-f">{Currency.GAT} {t('referral.turnover')}</th>
              <th className="bd-t-0-f">{Currency.USDT} {t('referral.turnover')}</th>
              <th className="bd-t-0-f">{t('referral.total-turnover')}</th>
            </tr>
          </thead>
          <tbody>
          {referralInfo?.lines.map((line) => {
            const aftInUsdt = tokenBank?.aufPrice ? line.aufTurnover * tokenBank.aufPrice : 0;
            const gatInUsdt = tokenBank?.gatPrice ? line.gatTurnover * tokenBank.gatPrice : 0;
            const totalUsdt = aftInUsdt + gatInUsdt + line.usdTurnover;

            return (
              <tr key={`ref-line-${line.line}`}>
                <td>{line.line} <span>({line.rewardPercents}%)</span></td>
                <td>{line.referralsCount}</td>
                <td>
                  <FormattedNumber
                    className="d-block"
                    value={line.aufEarned}
                    postfix={Currency.AFT}
                    decimals={2}
                    floor
                  />
                  <FormattedNumber
                    className={`d-block ${line.aufMissed ? '' : ''}`}
                    value={line.aufMissed}
                    postfix={Currency.AFT}
                    decimals={2}
                    floor
                  />
                </td>
                <td>
                  <FormattedNumber
                    className="d-block"
                    value={line.aufTurnover}
                    postfix={Currency.AFT}
                    decimals={2}
                    floor
                  />
                  {aftInUsdt > 0 && (
                    <FormattedNumber
                      className="d-block tx-normal tx-14"
                      value={aftInUsdt}
                      suffix="≈$"
                      floor
                    />
                  )}
                </td>
                <td>
                  <FormattedNumber
                    className="d-block"
                    value={line.gatTurnover}
                    postfix={Currency.GAT}
                    decimals={2}
                    floor
                  />
                  {gatInUsdt > 0 && (
                    <FormattedNumber
                      className="d-block tx-normal tx-14"
                      value={gatInUsdt}
                      suffix="≈$"
                      floor
                    />
                  )}
                </td>
                <td>
                  <FormattedNumber
                    className="d-block"
                    value={line.usdTurnover}
                    postfix={Currency.USDT}
                    decimals={2}
                    floor
                  />
                </td>
                <td>
                  <FormattedNumber
                    className="d-block"
                    value={totalUsdt}
                    postfix={Currency.USDT}
                    decimals={2}
                    floor
                  />
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>

      <ReferralSubLines />
    </div>
  )
});

export default Referral;
