import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faUser, faWallet} from "@fortawesome/pro-regular-svg-icons";
import {faMoneyBillTrendUp, faMoneyFromBracket, faSackDollar, faUserGroup} from "@fortawesome/pro-duotone-svg-icons";

export const ENV = {
  IS_TEST_ENV: process.env.REACT_APP_IS_TEST_ENV as string === 'true',
  API_URL: process.env.REACT_APP_API_URL as string,
  TG_BOT_URL: process.env.REACT_APP_TG_BOT_URL || 'tg://resolve?domain=aurous_finance_bot',
  IS_UNDER_MAINTENANCE: process.env.REACT_APP_IS_UNDER_MAINTENANCE as string === 'true',
}

export const refIdRx = RegExp(/^[a-zA-Z0-9_.]+$/);
export const usernameRx = RegExp(/^[a-zA-Z0-9@!#+\-_.]+$/);
export const nameSurnameRx = RegExp(/^\p{L}+([- ]\p{L}+)*$/u);
export const emailRx = RegExp(/(.+)@(.+){2,}\.(.+){2,}/);

export enum Language {
  RU = 'ru',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
}

export enum AccountRole {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT',
}

export enum PromoStatus {
  ACTIVE = 'ACTIVE',
  APPLIED = 'APPLIED',
  FINISHED = 'FINISHED',
}

export interface IAccount {
  userId: number;
  username: string;
  refId: string;
  email: string;
  role: AccountRole;
}

export interface IProfileUpdateProps {
  username: string;
  name: string;
  surname: string;
  ownRefId: string;
}

export interface IProfile extends IProfileUpdateProps {
  email: string;
  uplinerReferralId: string;
  telegramEnabled: boolean;
}

export interface IToken {
  token: string | null;
}

export enum PageRoutes {
  ROOT = '/',
  REGISTER = '/register',
  LOGIN = '/login',
  WALLET = '/wallet',
  HISTORY = '/history',
  INVEST = '/invest',
  WITHDRAWAL = '/withdrawal',
  REFERRAL = '/referral',
  PROFILE = '/profile',
  ACTIONS = '/actions',
  RESET_PASSWORD = '/reset-password',
  PASSWORD_RESET = '/password-reset',
}

interface Page {
  path: PageRoutes;
  title: string;
  icon?: IconDefinition;
}

export const Pages: Page[] = [
  {
    path: PageRoutes.WALLET,
    title: 'wallet.title',
    icon: faWallet,
  },
  {
    path: PageRoutes.HISTORY,
    title: 'history.title',
    icon: faWallet,
  },
  {
    path: PageRoutes.INVEST,
    title: 'invest.title',
    icon: faSackDollar,
  },
  {
    path: PageRoutes.WITHDRAWAL,
    title: 'withdrawal.title',
    icon: faMoneyFromBracket,
  },
  {
    path: PageRoutes.REFERRAL,
    title: 'referral.nav-title',
    icon: faUserGroup,
  },
  {
    path: PageRoutes.PROFILE,
    title: 'profile.title',
    icon: faUser,
  },
  {
    path: PageRoutes.ACTIONS,
    title: 'actions.title',
    icon: faMoneyBillTrendUp,
  },
];

export enum Sort {
  ASC = 'asc',
  DESC = 'desc',
}

interface KeyStringObject {
  [key: string]: string;
}

export enum ITransactionType {
  DEPOSIT_USD = 'DEPOSIT_USD',
  DEPOSIT_USDT = 'DEPOSIT_USDT',
  BUY_GAT = 'BUY_GAT',
  INVEST_USD = 'INVEST_USD',
  INVEST_USDT = 'INVEST_USDT',
  INVEST_AUF = 'INVEST_AUF', // TODO: To be removed
  INVEST_AFT = 'INVEST_AFT',
  GAT_INVEST_AUF = 'GAT_INVEST_AUF', // TODO: To be removed
  GAT_INVEST_AFT = 'GAT_INVEST_AFT',
  GAT_INVEST_USD = 'GAT_INVEST_USD',
  GAT_INVEST_USDT = 'GAT_INVEST_USDT',
  GAT_INVEST_GAT = 'GAT_INVEST_GAT',
  PASSIVE_PAYOUT = 'PASSIVE_PAYOUT',
  REFERRAL_PAYOUT = 'REFERRAL_PAYOUT',
  ADMIN_DEPOSIT_USD = 'ADMIN_DEPOSIT_USD',
  ADMIN_DEPOSIT_USDT = 'ADMIN_DEPOSIT_USDT',
  ADMIN_DEPOSIT_AUF = 'ADMIN_DEPOSIT_AUF', // TODO: To be removed
  ADMIN_DEPOSIT_AFT = 'ADMIN_DEPOSIT_AFT',
  ADMIN_DEPOSIT_GAT = 'ADMIN_DEPOSIT_GAT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum Currency {
  USD = 'USD', // TODO: To be removed
  USDT = 'USDT',
  AUF = 'AUF', // TODO: To be removed
  AFT = 'AFT',
  GAT = 'GAT',
}

export enum CryptoTicker {
  BTC = 'BTC',
  ETH = 'ETH',
  ERC_USDT = 'ERC_USDT',
  TRC_USDT = 'TRC_USDT',
}

export interface IWallet {
  id: string;
  currency: Currency;
  balance: number;
  lockedBalance: number;
  usdRate: number;
  usdBalance: number;
  usdLockedBalance: number;
  metadata: {
    fakeBalance?: number;
  }
}

export interface ISort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export interface Pageable {
  sort: ISort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface Paged<T> {
  content: T[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number
  number: number;
  sort: ISort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export enum ITransactionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  LOCKED_BY_X_FACTOR = 'LOCKED_BY_X_FACTOR',
  MISSED = 'MISSED',
  ERROR = 'ERROR',
}

export interface ITransactionOperation {
  id: number;
  currency: string;
  balanceDiff: number;
  balanceDiffUsd: number;
}

export interface ITransaction {
  id: string;
  status: ITransactionStatus;
  type: ITransactionType;
  creationTimestamp: string;
  updateTimestamp: string;
  operations: ITransactionOperation[];
  metadata: KeyStringObject;
}

export interface IDepositOption {
  cryptocurrency: CryptoTicker;
  address: string;
}

export enum InvestPlanStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IInvestPlan {
  id: string;
  status: InvestPlanStatus;
  totalUsdInvested: number;
  totalAufInvested: number;
  paidAufPassive: number;
  paidAufRef: number;
  aufInvestmentBody: number;
  maxAufProfit: number;
  totalAufPaid: number;
}

export interface IGatInvestPlan {
  id: string;
  status: InvestPlanStatus;
  totalUsdInvested: number;
  totalGatInvested: number;
  totalAufInvested: number;
  paidGatPassive: number;
  paidGatRef: number;
  gatWeightDaysCount: number;
  gatInvestmentBody: number;
  totalGatPaid: number;
  frozenUntil: string;
  workUntil: string;
}

export interface IUsdInvestPlan {
  id: string;
  status: InvestPlanStatus,
  totalUsdInvested: number;
  totalGatInvested: number;
  totalAufInvested: number;
  paidUsdPassive: number;
  usdInvestmentBody: number;
  workUntil: string;
}

export interface IReferralLine {
  line: number;
  rewardPercents: number;
  referralsCount: number;
  aufMissed: number;
  aufEarned: number;
  aufTurnover: number;
  gatTurnover: number;
  usdTurnover: number;
}

export interface IRankInfo {
  personalTurnover: number;
  mainBranchTurnover: number;
  mainBranchUsername: string | null;
  secondaryBranchUsername: string | null;
  secondaryBranchTurnover: number;
  otherBranchesTurnover: number;
  rankLevel: number;
}

export interface IRankRule {
  level: number;
  name: string;
  personalTurnover: number;
  mainBranchTurnover: number;
  secondaryBranchTurnover: number;
  otherBranchesTurnover: number;
  rankBonus: number;
}

export interface IReferralInfo {
  refId: string;
  refLevel: number;
  lines: IReferralLine[];
  totalReferrals: number;
  totalAufEarned: number;
  totalAufMissed: number;
}

export interface IReferralRule {
  level: number;
  rewardPercents: number;
  minPersonalAufDeposit: number;
  minPersonalAufTurnover: number;
  minPersonalUsdDeposit: number;
  minPersonalUsdTurnover: number;
}

export interface IBuyGatResponse {
  creationTimestamp: string;
  id: string;
  metadata: {
    buyCurrency: string;
    buyCurrencyAmount: number;
    buyGatAmount: number;
    gatBonusAmount: number;
  }
  operations: Array<{id: number, currency: Currency, balanceDiff: number}>;
  status: string;
  type: string;
  updateTimestamp: string;
}

export interface ITokenBank {
  aufPrice: number;
  gatPrice: number;
  nextAufUpdateIn: number;
  gatSoldCount: number;
  maxNumberGat: number;
}

export interface IReferralSubLine {
  username: string;
  email: string;
  personalTurnover: number;
  branchTurnover: number;
  referralLine: number;
  wholeTurnover: number;
}

export interface IPromoTurkeyFirstLineTurnover {
  username: string;
  personalTurnoverInUsd: number;
}

export interface IPromoTurkeyData {
  personalTurnoverInUsd: number;
  totalTurnover: number;
  expectedTotalTurnover: number;
  firstLineUserTurnovers: IPromoTurkeyFirstLineTurnover[],
  promoStartFrom: string;
  promoStatus: PromoStatus;
}
