import {action, makeAutoObservable, observable} from 'mobx';
import {api} from "@helpers/api";
import {clearAuthTokens, setAuthTokens} from "axios-jwt";
import {StatusResponse} from "@hooks/useResponseHandler";
import {
  CryptoTicker,
  Currency,
  IAccount,
  IDepositOption,
  IGatInvestPlan,
  IInvestPlan,
  IProfile,
  IProfileUpdateProps, IPromoTurkeyData,
  IRankInfo,
  IRankRule,
  IReferralInfo,
  IReferralRule, IReferralSubLine,
  IToken,
  ITransaction,
  IUsdInvestPlan,
  IWallet,
  Paged
} from "../constants";

interface LoginResponse {
  token: string;
}

export class AccountStore {
  @observable
  private _refID: string | null = localStorage.getItem('referral');

  @observable
  private _emptyPartnersHidden: boolean = localStorage.getItem('hide-empty') === '1';

  @observable
  private _token: string | null = null;

  @observable
  private _isLoggedIn: boolean;

  constructor() {
    makeAutoObservable(this);
    this._isLoggedIn = !!localStorage.getItem('token');
    this.setToken(localStorage.getItem('token'));
  }

  @action
  public handleReferral(searchParams: URLSearchParams) {
    this.setRefID(searchParams.get('ref'));
  }

  @action
  setRefID(value: string | null) {
    if (value) {
      this._refID = value;
      localStorage.setItem('referral', value);
    }
  }

  get
  refID() {
    return this._refID;
  }

  @action
  setEmptyPartnersHidden(value: boolean) {
    localStorage.setItem('hide-empty', value ? '1' : '0');
    this._emptyPartnersHidden = value;
  }

  get
  emptyPartnersHidden() {
    return this._emptyPartnersHidden;
  }

  @action
  setToken(value: string | null) {
    this._token = value;

    if (value) {
      localStorage.setItem('token', value);
      setAuthTokens({
        accessToken: value,
        refreshToken: value,
      });
    } else {
      this.resetStore();
    }
  }

  get
  token() {
    return this._token;
  }

  @action
  setLoggedIn(value: boolean) {
    this._isLoggedIn = value;

    if (!value) {
      this.resetStore();
    }
  }

  get
  isLoggedIn() {
    return this._isLoggedIn;
  }

  @action
  register(username: string, email: string, password: string, uplinerRefId: string) {
    return api<any>({method: 'post', path: '/auth/register', data: {username, email, password, uplinerRefId}});
  }

  @action
  login(login: string, password: string) {
    return api<StatusResponse<LoginResponse>>({method: 'post', path: '/auth/login', data: {login, password}}).then((response) => {
      if (response.data?.token) {
        this.setToken(response.data.token);
        this.setLoggedIn(true);
      }
      return response;
    });
  }

  @action
  resetPasswordToken(usernameOrEmail: string) {
    return api<StatusResponse<any>>({method: 'post', path: '/auth/reset-password-token', data: {usernameOrEmail}});
  }

  @action
  getWallets() {
    return api<IWallet[]>({method: 'get', path: '/wallet'});
  }

  @action
  getTransactions(page = 0, size = 10) {
    return api<Paged<ITransaction>>({method: 'get', path: '/transaction', data: {page, size}});
  }

  @action
  getDepositOptions() {
    return api<IDepositOption[]>({method: 'get', path: '/deposit/usd/options'});
  }

  @action
  createDepositOption(crypto: CryptoTicker) {
    return api<StatusResponse<IDepositOption>>({method: 'post', path: '/deposit/usd/generate', data: {crypto}});
  }

  @action
  getInvestPlan() {
    return api<IInvestPlan>({method: 'get', path: '/invest/plan'});
  }

  @action
  invest(amount: number, currency = Currency.USD) {
    return api<StatusResponse<any>>({method: 'post', path: '/invest', data: {currency, amount}});
  }

  @action
  getGatInvestPlans() {
    return api<IGatInvestPlan[]>({method: 'get', path: '/gat-invest/plans'});
  }

  @action
  investGat(amount: number, currency = Currency.USD) {
    return api<StatusResponse<any>>({method: 'post', path: '/gat-invest', data: {currency, amount}});
  }

  @action
  getUsdInvestPlan() {
    return api<IUsdInvestPlan>({method: 'get', path: '/usd-invest/plan'});
  }

  @action
  investUsd(amount: number, currency = Currency.USD) {
    return api<StatusResponse<any>>({method: 'post', path: '/usd-invest', data: {currency, amount}});
  }

  @action
  exitUsd() {
    return api<StatusResponse<any>>({method: 'post', path: '/usd-invest/emergency-exit'});
  }

  @action
  buyGat(currencyAmount: number, currency = Currency.USD) {
    return api<StatusResponse<any>>({method: 'post', path: '/buy/gat', data: {currency, currencyAmount}});
  }

  @action
  getAccountInfo() {
    return api<IAccount>({method: 'get', path: '/account'});
  }

  @action
  getProfileInfo() {
    return api<IProfile>({method: 'get', path: '/profile'});
  }

  @action
  updateProfileInfo(data: IProfileUpdateProps) {
    return api<StatusResponse<IProfile>>({method: 'post', path: '/profile', data});
  }

  @action
  disconnectFromTelegram() {
    return api<StatusResponse<IProfile>>({method: 'delete', path: '/profile/tg-token'});
  }

  @action
  getTgToken() {
    return api<StatusResponse<IToken>>({method: 'get', path: '/profile/tg-token'});
  }

  @action
  generateTgToken() {
    return api<StatusResponse<IToken>>({method: 'post', path: '/profile/tg-token'});
  }

  @action
  changePassword(currentPass: string, newPassword: string) {
    return api<StatusResponse<null>>({method: 'post', path: '/auth/change-password', data: {currentPass, newPassword}});
  }

  @action
  changePasswordWithToken(token: string, password: string) {
    return api<StatusResponse<null>>({method: 'post', path: '/auth/new-password', data: {token, password}});
  }

  @action
  getRankInfo() {
    return api<StatusResponse<IRankInfo>>({method: 'get', path: '/rank'});
  }

  @action
  getRankRules() {
    return api<StatusResponse<IRankRule[]>>({method: 'get', path: '/rank/rules'});
  }

  @action
  getReferralInfo() {
    return api<IReferralInfo>({method: 'get', path: '/referral'});
  }

  @action
  getReferralRules() {
    return api<IReferralRule[]>({method: 'get', path: '/referral/rules'});
  }

  @action
  getReferralSubLine(username: string) {
    return api<StatusResponse<IReferralSubLine[]>>({method: 'get', path: `/referral/first-line/${username}`, data: {username}});
  }

  @action
  withdraw(amount: number, currency = Currency.USD, usdtTrc20Address: string) {
    return api<StatusResponse<any>>({method: 'post', path: '/withdrawal', data: {currency, amount, usdtTrc20Address}});
  }

  @action
  getPromoTurkey() {
    return api<StatusResponse<IPromoTurkeyData>>({method: 'get', path: '/promo/turkey'});
  }

  @action
  applyForPromoTurkey() {
    return api<StatusResponse<IPromoTurkeyData>>({method: 'post', path: '/promo/turkey'});
  }

  /**
   * @deprecated
   * This action is not exist anymore. Will be changed to other API.
   *
   * @param token
   */
  @action
  passwordReset(token: string) {
    return api<any>({method: 'get', path: '/auth/password-reset', data: {token}});
  }

  @action
  logout() {
    this.resetStore();
  }

  @action
  resetStore() {
    localStorage.removeItem('token');
    this._token = null;
    this._isLoggedIn = false;
    clearAuthTokens();
  }
}
