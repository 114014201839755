import React from 'react';
import {format} from 'date-fns';
import {ru, enUS} from 'date-fns/locale';

export interface FormattedDateProps {
  date: string | Date;
  dateFormat?: string;
}

export default function FormattedDate({date, dateFormat = 'dd.MM.yyyy HH:mm'}: FormattedDateProps) {
  const dateLanguages = {
    'ru': ru,
    'en': enUS,
  };
  const lang = 'ru';

  return (
    <>
      {format(new Date(date), dateFormat, {locale: dateLanguages[lang]})}
    </>
  );
}
