import {useTranslation} from "react-i18next";

import useQuery from "@hooks/useQuery";
import {PasswordResetRequest} from "@pages/ResetPassword/components/PasswordResetRequest";
import {ChangePasswordRequest} from "@pages/ResetPassword/components/ChangePasswordRequest";

export default function ResetPassword() {
  const {t} = useTranslation();

  const query = useQuery();
  const token = query.get('token');

  return (
    <div className="container">
      <div className="card p-0 bg-dark">
        <h2 className="mb-3 mt-3 ms-5 tx-24">{t('reset-password.title')}</h2>

        <div className="card ht-100p gradient-primary">
          {!token && <PasswordResetRequest />}
          {token && <ChangePasswordRequest token={token} />}
        </div>
      </div>
    </div>
  );
}
